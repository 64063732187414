.modal-backdrop {
 	z-index: 1040 !important;
}

.pac-container {
  z-index: 9999 !important; /* Ensure the dropdown appears on top */
}

.modal-dialog {

	.modal-header {
		height: 50px;
		padding: 14px 19px;
		border-bottom: 1px solid #f1f1f1;

		.modal-title {
			font-size: 16px;
			color: #5a5a5a;
			font-weight: 500;
		}

		.close {
			color: #333;
			float: right;
			font-size: 16px;
			font-weight: 500;
			margin: -21px 5px 0 0;
			opacity: 1;

			&:hover,
			&:focus {
				text-decoration: none;
			}
		}
	}

	.modal-body {
		padding: 0px;
	}

	.modal-footer {
		clear: both;
	}

	button {
		border: 0;
		cursor: pointer;
		margin: 0 0 0 2px;
	}

	.btn:disabled {
		background-color: #ffffff !important;
		color: #bdbdbd;
	}

	.ngdialog-button {
		margin: 0 18px 0 18px !important;
	}

	// -- Popup buttons
	.popup-header-close-button {
		background: none;
		color: #5a5a5a;
		font-weight: 500;
		font-size: 16px;
	}

	.popup-footer-delete-button {
		color: #ff6a6a;
		background: none;
		font-weight: 500;
		font-size: 14px;
	}

	.popup-footer-yes-button,
	.popup-footer-cancel-button {
		background: none;
		font-weight: 500;
		font-size: 16px;
		color: #32a1f7;
	}

	.popup-footer-save-button {
		background: none;
		font-weight: 500;
		font-size: 16px;
		color: #32a1f7;
	}

	.popup-footer-save-button:disabled {
		color: #bdbdbd;
	}

	.user-send-message-button {
		border: 1px solid $primary_color_blue;
		box-shadow: none;
		width: 34px;
		height: 30px;
		background: #ffffff;
		box-shadow: none;
		border-radius: 4px;
		text-align: center;

		i {
			// margin:0 2px;
		}
	}

	.message-send-button {
		color: #32a1f7;
		background-color: #ffffff;
		font-weight: 500;
		font-size: 14px;
	}

	.message-attachment-button {
		width: 25px;
		height: 15px;
		background-color: #ccc;
	}

	.new-message-button {
		background-color: none;
		color: #ffffff;
		width: 100%;
		height: 54px;
		font-weight: 500;
		font-size: 14px;
		text-align: center;
	}

	.btn-add-position {
		background: none;
		color: #2b6edc;
	}
}

.notification-modal {
	.modal-content {
		border: none;
		background-color: transparent;
		box-shadow: none;
	}
}

.alertNew {
	.modal-body {
		padding: 15px;
	}
}


.go-pro {

	.modal-dialog {
		width: 830px !important;
	}

	.modal-content {
		height: 545px;
		overflow: hidden;
	}

	.pop-up-left {
		float: left;
		width: 490px;
		padding: 30px 0 30px 25px;

		.go-pro-pop-title {
			font-size: 37px;
			font-weight: 300;
			color: #5a5a5a;
		}

		.go-pro-pop-content {
			font-size: 15px;
			color: #868686;
			font-weight: 400;
		}

		.card-details-wrapper {
			.form-control {
				color: #999;
			}

			select.form-control {
				margin: 0;
				-webkit-box-sizing: border-box;
				-moz-box-sizing: border-box;
				box-sizing: border-box;
				-webkit-appearance: none;
				-moz-appearance: none;
				background-image: linear-gradient(45deg, transparent 50%, gray 50%),
					linear-gradient(135deg, gray 50%, transparent 50%);
				background-position: calc(100% - 20px) calc(50%),
					calc(100% - 15px) calc(50%), calc(100% - 2.5em) 0.5em;
				background-size: 5px 5px, 5px 5px, 1px 1.5em;
				background-repeat: no-repeat;
			}

			p {
				font-size: 14px;
				color: #868686;
				margin-top: 15px;
			}

			.card-address-wrap,
			.card-details-wrap,
			.card-name-wrap {
				display: inline-flex;

				.form-control {
					height: 40px;
					padding: 9px 12px;
				}
			}

			.card-details-wrap {
				.form-control {
					height: 40px;
					padding: 9px 12px;
				}
			}

			.card-name {
				height: 40px;
				width: 218px;
			}

			.card-address {
				height: 40px;
				width: 365px;
			}

			.card-city {
				height: 40px;
				width: 120px;
			}

			.card-state {
				height: 40px;
				width: 100px;
				margin-left: 10px;
			}

			.card-country {
				height: 40px;
				width: 120px;
				margin-left: 10px;
			}
			.card-postal {
				height: 40px;
				width: 80px;
				margin-left: 10px;
			}

			.card-number {
				width: 256px;
				height: 40px;
				cursor: pointer;
			}

			.card-cvv {
				width: 66px;
				height: 40px;
				margin-left: 10px;
			}

			.card-expire {
				width: 98px;
				height: 40px;
				margin-left: 10px;
			}

			.card-thumbs {
				display: inherit;
				margin: 54px 0 0 10px;

				.card {
					display: block;
					background-color: rgba(0, 0, 0, 0);
					background-image: url("../../images/credit-cards.png");
					background-position: 0 0;
					background-size: 132px auto;
				}

				.visa {
					width: 37px;
					height: 25px;
					background-position: 0px -2px;
				}

				.master {
					width: 33px;
					height: 20px;
					background-position: -36px -6px;
					margin-top: 3px;
				}

				.discover {
					width: 35px;
					height: 23px;
					background-position: -69px -4px;
				}

				.amex {
					width: 27px;
					height: 27px;
					background-position: -104px -2px;
				}
			}

			.privacy-agree {
				.form-check-label {
					color: #868686;
					font-size: 15px;
					font-weight: 400;
				}
			}

			.error,
			.privacy-agree {
				clear: both;
				float: left;
				margin-top: 60px;
			}
		}
	}

	.pop-up-right {
		float: left;
		width: 338px;
		background-color: #f2f2f2;
		padding: 30px 25px 30px 25px;

		a {

			&:hover,
			&:focus {
				text-decoration: none;
			}
		}

		.plan {
			width: 100%;
			height: 96px;
			background-color: #fff;
			border: 2px solid #eaeaea;
			border-radius: 10px;
			margin-bottom: 15px;
			padding: 20px;
			position: relative;
			transition: 0.2s;

			&:hover {
				border: 2px solid #1d6cee;
				text-decoration: none;
			}

			.plan-price {
				font-size: 20px;
				color: #868686;
			}

			.discount {
				font-size: 15px;
				color: #5a5a5a;
			}

			.verified-icon {
				display: none;
				background-color: rgba(0, 0, 0, 0);
				background-image: url("../../images/verified-icon.png");
				background-repeat: no-repeat;
				background-position: 0 0;
				background-size: cover;
				float: right;
				height: 21px;
				width: 21px;
				position: absolute;
				top: 20px;
				right: 20px;
			}
		}

		.month-plan.plan {
			padding: 35px 20px;
		}

		.plan.active {
			border: 2px solid #1d6cee;

			.plan-price {
				color: #3f82f1;
			}

			.verified-icon {
				display: block;
			}
		}

		.description {
			font-size: 15px;
			color: #868686;
			display: none;
		}

		.description.active {
			display: block;
			margin-bottom: 80px;
		}
	}

	.btn-purchase-complete,
	.btn-purchase-cancel {
		width: 100%;
		border-radius: 5px;
		padding: 17px 0;
		height: 60px;
		box-shadow: 1px 1px 1px 1px rgba(187, 187, 187, 0.3);
		font-size: 17px;
		transition: 0.2s;
	}

	.btn-purchase-complete {
		margin-bottom: 10px;
		background-color: #206ff0;
		color: #fff;

		&:hover {
			background-color: #255dba;
		}

		&:disabled {
			background-color: #dddde0 !important;
		}
	}

	.btn-purchase-cancel {
		margin: 0;
		background-color: #fff;
		color: #206ff0;
		border: 1px solid #206ff0;

		&:hover {
			color: #fff;
			background-color: #255dba;
		}
	}
}

.update-payment {

	.modal-dialog {
		width: 522px !important;
	}

	.modal-content {
		height: auto;
		overflow: hidden;
	}

	.pop-up-left {
		float: left;
		width: 520px;
		padding: 30px 0 30px 25px;
	}

	.btn-purchase-complete {
		margin-right: 7px;
		margin-bottom: 0px !important;
		width: 256px;
	}

	.btn-purchase-cancel {
		width: 185px;
	}
}

.dlt {
	.modal-content {
		width: 620px;
		.modal-header {
			.cancel-title {
				float: left;
				font-size: 18px;
				color: #5a5a5a;
				margin: 0;
			}
		}

		.modal-body {
			padding: 15px !important;
			border-bottom: 1px solid #f7f7f7;

			p {
				font-size: 14px;
				color: #5c5c5c;
				margin-top: 10px;
			}
		}

		.modal-footer {
			padding: 0px !important;
			.button-wrapper {
				float: right;
				padding: 5px 19px;

				.btn {
					background-color: rgba(0, 0, 0, 0);
				}

				.btn-deactivate {
					color: #ff4a4a;
				}
			}
		}
	}
}


.notification-modal {
	.modal-content {
		border: none;
		background-color: transparent;
	}
}

.application {

	.modal-dialog {
		border-radius: 4px;
		width: 912px;
		min-height: 350px;
	}

	.modal-content {
		max-height: 650px;
		overflow: hidden;
		width: 100%;

		.modal-header {
			height: 47px;
			padding: 0 16px;
			width: 100%;
			border-bottom: solid 1px rgba(0, 0, 0, 0.12);

			.modal-title,
			.close {
				color: #333;
				font-size: 16px;
				font-weight: 500;
				line-height: 47px;
				opacity: 1;
			}

			.close {
				margin-top: -32px;
				line-height: 20px;
			}
		}

		.content {
			max-height: 600px;
			overflow: auto;
			width: 100%;

			.applicants-type-title {
				color: #303030;
				font-size: 18px;
				width: 100%;
				height: 48px;
				line-height: 21px;
				padding: 16px 15px 11px;
				border-top: solid 1px rgba(0, 0, 0, 0.12);
			}

			.empty-case-no-appliations {
				height: 62px;
				width: 100%;
				color: rgba(0, 0, 0, 0.4);
				font-size: 13px;
				line-height: 15px;
				padding: 26px 0 21px;
				text-align: center;
				border-top: solid 1px rgba(0, 0, 0, 0.12);
				border-bottom: solid 1px rgba(0, 0, 0, 0.12);
			}

			.content-item {
				border-top: solid 0.6px rgba(0, 0, 0, 0.12);
				color: #2b6edc;
				font-size: 16px;
				font-weight: 500;
				float: left;
				padding: 19px 16px;
				height: auto;
				width: 100%;

				&.unread {
					background-color: #e1f4ff;
				}

				.checkboxWrapper>span {
					color: #333;
					font-size: 13px;
					display: block;
					margin-left: 21px;
					line-height: 1.4;
					width: 167px;
					word-wrap: break-word;
					height: auto;
					font-weight: 400;

					&:hover {
						text-decoration: none;
						color: #333;
					}
				}

				.animated-background {
					height: 19px;
					width: 150px;
				}

				.profile-picture {
					margin-right: 14px;
					width: 41px;
					height: 41px;
					border-radius: 3px;
					border: solid 1px #979797;
					background-size: cover;

					&.has-video {
						position: relative;

						&:after {
							background-color: transparent;
							background-image: url(../../images/image-sprite-1.png);
							background-repeat: no-repeat;
							background-position: -36px -246px;
							position: absolute;
							content: "";
							width: 28px;
							height: 30px;
							top: 50%;
							left: 50%;
							transform: translate(-50%, -50%) scale(0.5);
						}
					}
				}

				a,
				span {
					color: #2b6edc;
					cursor: pointer;
					max-width: 250px;
					//height: 30px;
					line-height: 20px;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;

					&:hover {
						color: #2b6edc;
						text-decoration: none;
					}
				}

				a.date,
				span.date {
					color: rgba(0, 0, 0, 0.4);
					font-size: 14px;
				}

				&:nth-child(2n) {
					.animated-background {
						width: 200px;
					}
				}

				&:nth-child(3n) {
					.animated-background {
						width: 170px;
					}
				}

				&:nth-child(4n) {
					.animated-background {
						width: 220px;
					}
				}

				.options {
					margin-top: 4px;

					//position: relative;
					.options-ui-wrap {
						position: inherit;
						margin-left: 6px;
						padding-left: 6px;
						border-left: solid 0.6px rgba(0, 0, 0, 0.12);

						button.user-view-answers-button {
							color: #ffffff;
							font-size: 13px;
							line-height: 1;
							height: 35px;
							border-radius: 3px;
							background-color: #289ffa;
							box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.12);

							&:hover {
								background-color: #2b6edc;
							}
						}

						button.user-add-to-list-button-large {
							color: #ffffff;
							font-size: 13px;
							line-height: 1;
							height: 35px;
							border-radius: 3px;
							width: 112px;

							i {
								background-image: url("../../images/add-to.png");
								background-size: cover;
								background-position: center;
								height: 15px;
								width: 15px;

								//position: relative;
								//top: -2px;
								//margin: 0;
							}
						}

						.add-to-list-dropdown-ui {
							//top: 0;
							right: 0;

							ul li span {
								color: #333;
								font-size: 13px;
								font-weight: 400;
								line-height: 1.5;
								height: auto;
							}
						}
					}

					.msg-to {
						.msg-ico {
							cursor: pointer;
							background-image: url("../../images/message.png");
							background-size: cover;
							background-position: center;
							box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.08);
							height: 35px;
							//margin-right: 12px;
							width: 35px;
						}
					}

					.options-ui-wrap-score-to {
						position: inherit;
						margin-left: 6px;
						border-left: solid 0.6px rgba(0, 0, 0, 0.12);

						.score-to {
							.score-ico {
								box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.08);
								height: 35px;
								width: 35px;
								padding-top: 6px;
								padding-left: 7px;
								font-weight: 500;
								border: 1.4px solid #2b6edc;
								box-sizing: border-box;
								border-radius: 4px;
							}
						}

						.like-to {
							.like-ico {
								box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.08);
								background-image: url("../../images/likeup.png");
								background-color: #bfdeff;
								height: 35px;
								width: 35px;
								padding-top: 6px;
								padding-left: 11px;
								font-weight: 500;
								border: 1.4px solid #2b6edc;
								box-sizing: border-box;
								border-radius: 4px;

								&:hover {
									background-color: #289ffa;
								}
							}

							.rescore-ico {
								box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.08);
								background-image: url("../../images/rescore.png");
								height: 35px;
								width: 35px;
								padding-top: 6px;
								padding-left: 11px;
								font-weight: 500;
								border: 1.4px solid #2b6edc;
								box-sizing: border-box;
								border-radius: 4px;

								&:hover {
									background-color: #bfdeff;
								}
							}

							.reject-ico {
								box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.08);
								background-image: url("../../images/reject.png");
								height: 35px;
								width: 35px;
								padding-top: 6px;
								padding-left: 11px;
								font-weight: 500;
								border: 1.4px solid #2b6edc;
								box-sizing: border-box;
								border-radius: 4px;

								&:hover {
									background-color: #ebb7b7;
								}
							}
						}

						.dlike-to {
							.dlike-ico {
								box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.08);
								background-image: url("../../images/likedown.png");
								background-color: #bfdeff;
								height: 35px;
								width: 35px;
								padding-top: 6px;
								padding-left: 11px;
								font-weight: 500;
								border: 1.4px solid #2b6edc;
								box-sizing: border-box;
								border-radius: 4px;

								&:hover {
									background-color: #ebb7b7;
								}
							}
						}
					}

					.view-resume {
						.resume-ico {
							cursor: pointer;
							background-image: url("../../images/view-resume.png");
							background-size: cover;
							background-position: center;
							box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.08);
							height: 35px;
							//margin-right: 12px;
							width: 35px;
						}
					}

					.scoreview-to {
						.scoreview-ico {
							box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.08);
							background-image: url("../../images/scoreview.png");
							height: 35px;
							width: 35px;
							padding-top: 6px;
							padding-left: 11px;
							font-weight: 500;
							border: 1.4px solid #2b6edc;
							box-sizing: border-box;
							border-radius: 4px;

							&:hover {
								background-color: #bfdeff;
							}
						}

						.notesview-ico {
							box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.08);
							background-image: url("../../images/Notes.png");
							height: 35px;
							width: 35px;
							padding-top: 6px;
							padding-left: 11px;
							font-weight: 500;
							border: 1.4px solid #2b6edc;
							box-sizing: border-box;
							border-radius: 4px;

							&:hover {
								background-color: #bfdeff;
							}
						}
					}
				}
			}
		}

		.content.no-dropdowns {
			.add-to-list-dropdown-ui {
				display: none;
			}
		}
	}
}


.jobPositions {

	.modal-dialog {
		margin-top: 10vh;
		width: 693px;
	}

	.modal-content {
		width: 100%;
		min-height: 333px;

		.modal-header {
			height: 47px;
			padding: 0 16px;
			width: 100%;
			border-bottom: solid 0.6px rgba(0, 0, 0, 0.12);

			.modal-title,
			.close {
				color: #333;
				font-size: 16px;
				font-weight: 500;
				line-height: 47px;
				opacity: 1;
			}

			.modal-title {
				width: 500px;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}

			.close {
				margin-top: -32px;
				line-height: 20px;
			}
		}

		.modal-body {
			padding: 15px;
		}

		.header {

			.title {
				color: #303030;
				font-size: 16px;
				font-weight: 500;
				line-height: 47px;
				height: 47px;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}

			.note-title {
				width: 500px;
			}

			.question-file-upload {
				margin-top: 3px;

				.question-file-upload-label {
					width: 292px;
					font-size: 14px;
					line-height: 1.71;
					color: rgba(0, 0, 0, 0.4);
					float: left;
					margin-right: 8px;
				}

				.question-file-upload-ui {
					float: left;
					width: 320px;

					.custom-file {
						display: block;
						margin: 0;
						position: relative;
						float: right;

						.custom-file-input {
							cursor: pointer;
							height: 41px;
							left: 0;
							opacity: 0;
							position: absolute;
							top: 0;
							width: 173px;
							z-index: 1;
						}
					}

					.btn-upload {
						min-width: 173px;
						height: 41px;
						border-radius: 4px;
						background-color: #ffffff;
						border: solid 2px #32a1f7;
						color: #32a1f7;
						font-size: 15.2px;

						&.uploading {
							border: solid 2px #b6b6b6;
							color: #b6b6b6;
							position: relative;
							text-align: left;
							width: 137px;

							i {
								background-image: url(../../images/refreshing.png);
								background-size: cover;
								display: block;
								height: 20px;
								position: absolute;
								right: 10px;
								top: 9px;
								width: 20px;
								-webkit-animation: rotateSpinner 1.2s linear infinite;
								animation: 1.2s linear 0s normal none infinite running rotateSpinner;
							}
						}

						&.edit {
							min-width: auto;
							position: relative;
							text-align: left;
							text-decoration: underline;
							padding-right: 35px;
							width: 100%;
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;
						}
					}

					i.close-new {
						background-image: url(../../images/close-blue.png);
						background-size: cover;
						cursor: pointer;
						display: block;
						float: left;
						height: 15px;
						margin-top: 13px;
						width: 15px;
						margin-left: -30px;
						z-index: 9;
						position: relative;
					}
				}
			}
		}

		.content {
			// height: 496px;
			overflow-y: auto;
			width: 100%;

			.content-item {
				height: 90px;
				padding: 5px 16px;
				width: 100%;
				padding-bottom: 5px;
				border-bottom: solid 0.6px rgba(0, 0, 0, 0.12);

				.animated-background.header {
					width: 200px;
					margin-bottom: 5px;
					height: 17px;
				}

				.animated-background.subtitle {
					width: 270px;
					height: 15px;
				}

				.content-item-left {
					width: 474px;

					.title {
						color: #2b6edc;
						font-size: 16px;
						font-weight: 500;
						margin: 0;
						height: 21px;
						width: 350px;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
					}

					.type {
						color: rgba(0, 0, 0, 0.4);
						font-size: 16px;
						margin: 0;
					}
				}

				.content-item-right {
					color: rgba(0, 0, 0, 0.87);
					font-size: 16px;
					margin: 0;
					word-wrap: break-word;
					word-break: break-all;
					width: 158px;
				}
			}
		}
	}
}

.showKeyword {
	.modal-content {
		.content {
			height: 540px !important;
			.content-item-left {
				width: 458px !important;
			}
		}
	}
}

.candidateSearch,
.showJobPositions {
	.modal-content {
		.content {
			height: 447px !important;
		}
	}

	.modal-body {
		padding: 0px !important;
	}
}

.application-answers {
	.modal-dialog {
		border: 0;
		margin-top: 0;
		margin-bottom: 0;
		margin-right: 0;
		width: 882px;
		height: 100vh;
		border-radius: 0;
		background-color: #f6f6f6;
		box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.12);
		-webkit-animation: ngdialog-flylrin 0.5s;
		animation: ngdialog-flylrin 0.5s;
	}

	.modal-content {
		background-color: #f6f6f6;

		.modal-header {
			border-bottom: none;
			height: auto !important;
			padding: 0px !important;
		}

		.modal-title {
			padding: 30px 30px 20px;
			width: 100%;
			height: 168px;

			.back-navigation {
				cursor: pointer;
				line-height: 18px;
				font-size: 18px;
				font-weight: 500;
				color: #2b6edc;
				width: 100%;
				margin-bottom: 20px;

				.close-pop-up {
					margin-top: 1px;
					margin-right: 15px;

					img {
						display: block;
					}
				}
			}

			.application-information {
				width: 100%;

				.job-information {
					width: 100%;
					line-height: 19px;
					font-size: 16px;
					color: #303030;
					margin-bottom: 10px;
				}

				.applicant-name {
					width: 100%;
					line-height: 28px;
					font-size: 24px;
					color: #2b6edc;
					margin-bottom: 5px;
				}

				.application-date {
					width: 100%;
					line-height: 15px;
					font-size: 13px;
					color: rgba(0, 0, 0, 0.4);
				}
			}
		}

		.content {
			position: relative;
			padding: 0 30px;
			width: 100%;
			height: calc(100vh - 168px);
			overflow: scroll;

			.scrolled-shadow {
				width: 680px;
				position: fixed;
				margin-left: -30px;
				height: 11px;
				z-index: 2;
				margin-top: -2px;
			}

			&.scrolled {
				.scrolled-shadow {
					box-shadow: inset 0px 11px 8px -10px rgba(0, 0, 0, 0.12);
					border-top: solid 1px rgba(0, 0, 0, 0.12);
				}
			}

			.answers-wrapper {
				width: 100%;
				float: left;
				margin-bottom: 20px;

				.question-item {
					position: relative;
					padding: 20px 20px 20px 64px;
					width: 100%;
					float: left;
					margin-bottom: 11px;
					border-radius: 5px;
					box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12);
					background-color: #ffffff;
					border: solid 0.6px rgba(0, 0, 0, 0.12);

					.question-number {
						position: absolute;
						top: 20px;
						left: 20px;
						line-height: 28px;
						font-size: 24px;
						font-weight: 500;
						color: rgba(0, 0, 0, 0.2);
					}

					.question-content-wrapper {
						width: 100%;
						float: left;
						word-break: break-word;
						word-wrap: break-word;

						.question-body {
							line-height: 22px;
							font-size: 16px;
							font-weight: 500;
							line-height: 1.38;
							color: #303030;
							margin-bottom: 10px;
							width: 100%;
						}

						.question-response {
							width: 100%;
							float: left;

							.correct-answer {
								color: #06964f;
								padding: 0 7px;
								height: 20px;
								display: inline-block;
								line-height: 22px;
								margin-left: 3px;
								margin-bottom: -20px;
								font-size: 11px;
								height: 20px;
								font-weight: 700;
								opacity: 0.4;
								border-radius: 11px;
								background-color: #b2e1d5;
							}

							.expected-answer {
								color: #adadad;
								padding: 0 7px;
								height: 20px;
								display: inline-block;
								line-height: 22px;
								margin-left: 3px;
								margin-bottom: -20px;
								font-size: 11px;
								height: 20px;
								font-weight: 700;
								opacity: 0.4;
								border-radius: 11px;
								background-color: #e9e9e9;
							}
						}

						.question-expected-answer {
							margin-left: -64px;
							float: left;
							margin-top: 22px;
							width: 620px;
							margin-bottom: -20px;
							padding: 11px 20px 12px;
							font-size: 14px;
							line-height: 1.57;
							letter-spacing: normal;
							color: rgba(0, 0, 0, 0.4);
							background-color: #f9f9f9;
							border-top: solid 0.6px rgba(0, 0, 0, 0.12);

							strong {
								font-weight: 500;
								color: #303030;
							}
						}

						.response-answer {
							width: 100%;
							font-size: 14px;
							line-height: 1.57;
							/* Madhu added overflow below */
							overflow: auto;
							color: rgba(0, 0, 0, 0.4);

							a {
								color: #319ef2;
							}
						}

						.response-multiple-value {
							width: 100%;

							ul.multiple-value-list {
								width: 100%;
								float: left;
								padding: 0;
								margin: 0;
								list-style: none;

								li {
									float: left;
									width: 100%;
									margin-bottom: 8px;
									position: relative;

									&:last-child {
										margin: 0;
									}

									.answer-checkbox {
										position: absolute;
										left: 0;
										top: 1px;
										display: inline-block;
										width: 20px;
										height: 20px;
										border-radius: 20px;
										background-color: rgba(0, 0, 0, 0.08);

										&.selected {
											background-color: #319ef2;
										}

										&:after {
											content: "";
											width: 10px;
											height: 5px;
											content: "";
											position: absolute;
											z-index: 2;
											top: 50%;
											left: 50%;
											border: 2px solid #ffffff;
											border-top: none;
											border-right: none;
											background: transparent;
											-webkit-transform: translate(-50%, -50%) rotate(-45deg);
											transform: translate(-50%, -50%) rotate(-45deg);
										}
									}

									.answer-label {
										width: 100%;
										padding-left: 30px;
										display: inline-block;
										font-size: 14px;
										line-height: 1.57;
										color: rgba(0, 0, 0, 0.4);
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

.interview {
	.modal-dialog {
		width: 100%;

		.modal-content {
			width: 941px;
			min-height: 540px;
			margin: 20px auto 0;
			background-color: #fff;
			border-radius: 5px;

			.modal-body {
				padding: 0;
			}

			label {
				font-size: 14px;
				font-weight: 400;
				color: #808080;
				margin-top: 5px;
			}

			.modal-header {
				border-bottom: 1px solid #f5f5f5;
				margin: 0;
				padding: 20px 20px 10px;

				.modal-title {
					font-size: 16px;
					font-weight: 500;
					margin: -6px 0 0 2px;
					width: 75%;
					//color: #5a5a5a;
				}

				.close {
					color: #333;
					float: right;
					font-size: 16px;
					font-weight: 500;
					margin: -21px 5px 0 0;

					//color: #5a5a5a;
					&:hover,
					&:focus {
						text-decoration: none;
					}
				}
			}

			.preloader-container {
				display: block;
				height: 200px;
				margin-top: 156px;
			}

			.pop-up-content {
				padding: 0 20px;

				.process-wrapper {
					display: flex;
					border: 1px solid #e5e5e5;
					border-radius: 5px;
					background-color: #fafafa;
					margin-top: 14px;
					width: -moz-fit-content;
					width: -webkit-fit-content;

					.step-wrapper {
						text-align: center;
						color: #e1e1e1;
						font-size: 16px;
						font-weight: 500;
						padding: 15px 30px;
						position: relative;
						margin-right: 26px;

						span {
							background-color: #e1e1e1;
							color: #fff;
							display: inline-block;
							width: 25px;
							height: 25px;
							border-radius: 50%;
							text-align: center;
							font-size: 17px;
							line-height: 1.5;
						}

						&:first-child {
							border-radius: 5px 0 0 5px;
						}

						&:last-child {
							margin: 0;
							border-radius: 0 5px 5px 0;
							width: 195px;
						}

						&.continue {
							&:after {
								content: "";
								display: block;
								height: 0;
								position: absolute;
								right: -28px;
								top: 0;
								width: 10px;
								background-image: url("../../images/image-sprite-1.png");
								background-position: -3px -136px;
								width: 30px;
								height: 55px;
							}

							&.active {
								&:after {
									border-bottom: 27px solid transparent;
									border-left: 28px solid #32a1f7;
									border-top: 28px solid transparent;
									width: 0;
									height: 0;
									background: none;
								}
							}
						}

						&.active {
							background-color: #32a1f7;
							color: #fff;

							span {
								background-color: #fff;
								color: #32a1f7;
							}

							&.prev {
								span {
									background-color: rgba(0, 0, 0, 0);
									background-image: url("../../images/image-sprite-1.png");
									background-repeat: repeat;
									background-position: -115px -134px;
									text-indent: -99999px;
								}

								&:after {
									border: none;
									background-image: url("../../images/image-sprite-1.png");
									background-position: -75px -136px;
									width: 30px;
									height: 55px;
								}
							}
						}
					}

					&.offers {
						width: 100%;

						.step-wrapper {
							width: 33%;
						}

						&.new {
							.step-wrapper {
								width: 50%;
							}
						}
					}

					&.reschedule {
						.step-wrapper {
							padding: 15px 63px;

							&:last-child {
								width: 267px;
							}
						}
					}
				}

				.time-dropdown {
					display: flex;
					margin-top: 20px;

					.label {
						width: 150px;
						font-size: 15px;
						margin: 5px 0 0 2px;
						color: #6d6d6d;
					}
				}

				.calendar-dropdown {
					@extend .time-dropdown;

					.dropdown {
						margin-top: 4px !important;

						a {
							-webkit-box-sizing: border-box;
							-moz-box-sizing: border-box;
							box-sizing: border-box;
							-webkit-appearance: none;
							-moz-appearance: none;

							background-image: linear-gradient(45deg, transparent 50%, gray 50%),
								linear-gradient(135deg, gray 50%, transparent 50%);
							background-position: calc(100% - 20px) calc(50%),
								calc(100% - 15px) calc(50%), calc(100% - 2.5em) 0.5em;
							background-size: 5px 5px, 5px 5px, 1px 1.5em;
							background-repeat: no-repeat;

							border: 1px solid #e5e5e5;
							box-shadow: none;
							border-radius: 20px;

							text-decoration: none;
							font-size: 13px;
							color: #555;
							width: 140px;
							height: 34px;
							padding: 8px 28px 8px 24px;
							line-height: 1.42857143;
						}

						.dropdown-menu {
							padding: 10px;
							margin-top: 5px;
							margin-left: 1px;

							.checkboxWrapper {
								margin: 0;
								padding-left: 0;

								input[type="checkbox"] {
									visibility: visible;
									opacity: 0;
									cursor: pointer;
									z-index: 9;

									&:focus+label {
										border-radius: 2px;
									}
								}

								input[type="checkbox"]:checked+label {
									background-color: #296cdc;
									border: 2px solid #296cdc;
								}

								label {
									color: #808080;
									margin-top: 3px;
								}

								span {
									width: auto;
								}

								.info {
									background-image: url("../../images/info-gray.png");
									background-repeat: no-repeat;
									background-size: cover;
									cursor: pointer;
									display: block;
									height: 19px;
									margin-left: 6px;
									margin-top: 1px;
									width: 19px;
								}
							}
						}
					}
				}

				.timezone-wrapper {
					.select-wrapper {
						width: 370px;
					}

					// Start SR 1/6/20
					.sync-calendar {
						background-color: #32a1f7;
						border: medium none;
						color: #fff;
						float: right;
						letter-spacing: -0.17px;
						padding: 8px 16px 8px 21px;
						margin-left: 81px;
					}
				}

				.job-details-wrapper {
					margin-top: 15px;
					height: 40px;

					div {
						display: flex;
						padding-left: 0;

						p {
							font-size: 17px;
							color: #303030;
							font-weight: 400;
							margin: 2px 0 0 15px;
						}
					}

					&.offers {
						float: left;
						height: auto;
						width: 100%;

						label {
							width: 90px;
						}
					}
				}

				.job-title-wrapper {
					display: flex;
					margin: 24px 0 0;
					padding-left: 0;

					label {
						margin-right: 15px;
					}

					#job-title-input {
						width: 250px;
					}

					.suggestionsDropMenu {
						box-shadow: 0 4px 8px 1px rgba(187, 187, 187, 0.6);
						left: 80px;
						top: 40px;
						width: 250px;
					}

					.sugItmContainer {
						position: absolute;
						top: 34px;
						left: 0;
						width: 100%;
						background-color: #fff;

						&:hover {
							background-color: #0e54e6;
						}
					}
				}
			}

			.zoom-wrapper {
				border-bottom: 1px solid #f5f5f5;
				border-top: 1px solid #f5f5f5;
				height: 59px;
				padding: 18px 20px 0;
				width: 100%;

				.checkboxWrapper {
					margin: 0;
					padding-left: 0;

					input[type="checkbox"] {
						visibility: visible;
						opacity: 0;
						cursor: pointer;
						z-index: 9;

						&:focus+label {
							border-radius: 2px;
						}
					}

					input[type="checkbox"]:checked+label {
						background-color: #296cdc;
						border: 2px solid #296cdc;
					}

					label {
						color: #808080;
						margin-top: 3px;
					}

					span {
						width: auto;
					}

					.info {
						background-image: url("../../images/info-gray.png");
						background-repeat: no-repeat;
						background-size: cover;
						cursor: pointer;
						display: block;
						height: 19px;
						margin-left: 6px;
						margin-top: 1px;
						width: 19px;
					}
				}

				.zoom-pmi-wrapper {
					label {
						margin: 0 17px 0 0;
					}

					.zoom-code {
						background-color: #d5ecfd;
						color: #32a1f7;
						font-size: 15px;
						font-weight: 500;
						line-height: 1.3;
						height: 19px;
						padding: 0 3px;
						width: auto;
					}

					.edit {
						background-image: url("../../images/pencil.png");
						background-repeat: no-repeat;
						background-size: cover;
						cursor: pointer;
						display: block;
						height: 19px;
						margin-left: 3px;
						margin-top: -1px;
						width: 19px;
					}

					.form-group {
						position: relative;
						margin-right: 6px;
						top: -10px;

						.form-control {
							border: solid 1px #bcbcbc;
							border-radius: 4px;
							width: 156px;
							height: 42px;
						}
					}

					button {
						border: solid 1px #bcbcbc;
						border-radius: 4px;
						box-shadow: 0px 3px 5px 0 rgba(50, 50, 93, 0.2);
						position: relative;
						height: 42px;
						width: 44px;

						padding: 0;
						top: -10px;
					}

					.btn-save {
						background-color: #2b6edc;
						margin-right: 3px;

						&:after {
							background-image: url("../../images/tick.png");
							background-repeat: no-repeat;
							background-size: cover;
							content: "";
							cursor: pointer;
							display: block;
							height: 15px;
							width: 20px;

							position: absolute;
							top: 13px;
							left: 12px;
						}
					}

					.btn-cancel {
						&:after {
							background-image: url("../../images/close.png");
							background-repeat: no-repeat;
							background-size: cover;
							content: "";
							cursor: pointer;
							display: block;
							height: 15px;
							width: 15px;

							position: absolute;
							top: 13px;
							left: 13px;
						}
					}
				}
			}

			.interview-select-title {
				font-size: 22px;
				color: #808080;
				font-weight: 300;
				margin: 22px 0 0;
			}

			.when-description {
				color: #bdbdbd;
				margin-top: 15px;

				span {
					color: #555151;
				}
			}

			.find-from-wrapper,
			.job-type-wrapper {
				display: flex;
				margin: 24px 0 25px 11px;

				.select-wrapper {
					display: block;
					height: 30px;
					margin-left: 10px;
					position: relative;
					width: 199px;
					border-radius: 20px;
					background-color: #fff;
					/* For browsers that do not support gradients */
					background-color: -webkit-linear-gradient(#fff,
							#eee);
					/* For Safari 5.1 to 6.0 */
					background-color: -o-linear-gradient(#fff,
							#eee);
					/* For Opera 11.1 to 12.0 */
					background-color: -moz-linear-gradient(#fff,
							#eee);
					/* For Firefox 3.6 to 15 */
					background-color: linear-gradient(#fff, #eee);
					/* Standard syntax */

					.form-control {
						position: absolute;
						background-color: rgba(0, 0, 0, 0);
						-webkit-box-sizing: border-box;
						-moz-box-sizing: border-box;
						box-sizing: border-box;
						-webkit-appearance: none;
						-moz-appearance: none;
						height: 30px;
						background-image: linear-gradient(45deg, transparent 50%, gray 50%),
							linear-gradient(135deg, gray 50%, transparent 50%);
						background-position: calc(100% - 20px) calc(50%),
							calc(100% - 15px) calc(50%), calc(100% - 2.5em) 0.5em;
						background-size: 5px 5px, 5px 5px, 1px 1.5em;
						background-repeat: no-repeat;
						border-color: #e5e5e5;
						box-shadow: none;
						border-radius: 20px;
						top: 0;
						left: 0;
						font-size: 12px;
						padding-right: 30px;
					}

					i {
						-webkit-animation: rotateSpinner 1.2s linear infinite;
						animation: 1.2s linear 0s normal none infinite running rotateSpinner;
						background-color: transparent;
						background-image: url("../../images/icon-preload-gray.png");
						background-repeat: repeat;
						background-position: 0 0;
						background-size: cover;
						display: block;
						height: 15px;
						width: 15px;

						position: absolute;
						top: 8px;
						right: 30px;
					}
				}
			}

			.job-type-wrapper {
				.select-wrapper {
					width: 140px;
					margin: 0 0 0 15px;
					position: relative;

					i {
						display: inline-block;
						width: 15px;
						height: 15px;
						background-image: url(../../images/icon-preload-gray.png);
						-webkit-animation: rotateSpinner 1.2s linear infinite;
						background-repeat: no-repeat;
						background-position: center;
					}
				}
			}

			.job-type-wrapper {
				&.fade {
					opacity: 0.6;
					pointer-events: none;
				}
			}

			.select-all-wrapper {
				border-top: 1px solid #f7f7f7;
				padding: 0 30px;
				width: 100%;

				.checkbox-wrapper {
					margin-top: 6px;

					input[type="checkbox"]:focus+label {
						border-radius: 100%;
					}
				}

				.select-all {
					font-size: 13px;
					color: #5a5a5a;
					font-weight: 400;
					width: 200px;
					float: left;
					margin: 12px 0 0 9px;
				}

				&.empty-select {
					color: #aeaeae;
					font-size: 12px;
					text-align: center;
					border-top: 1px solid #f7f7f7;
					margin-top: 25px;
					height: 280px;

					p {
						// width: 215px;
						margin: 0 auto;

						&:first-child {
							margin-top: 115px;
						}
					}
				}
			}

			.results-wrapper {
				height: 245px;
				width: 100%;
				overflow-y: auto;
				border-top: 1px solid #f7f7f7;
				padding: 0 30px;

				.results-row {
					border-bottom: 1px solid #f7f7f7;
					height: 80px;
					padding: 15px 0;

					&:last-child {
						border: 0 none;
					}

					.result-item {
						padding: 0;

						.checkbox-wrapper {
							margin-right: 20px;

							input[type="checkbox"]:focus+label {
								border-radius: 100%;
							}
						}

						.result-details {
							display: flex;

							.profile-picture {
								margin-right: 15px;
								height: 55px;
								position: relative;
								width: 51px;

								.img {
									background-size: cover;
									border-radius: 5px;
									height: 51px;
									width: 51px;
									position: relative;
									z-index: 1;
								}

								&:after {
									background-color: rgba(0, 0, 0, 0);
									background-image: url("../../images/default-profile-picture.jpg");
									background-repeat: no-repeat;
									background-position: center;
									background-size: cover;
									content: "";
									display: block;
									position: absolute;
									top: 0;
									left: 0;
									width: 51px;
									height: 51px;
									border-radius: 5px;
								}
							}

							.applier {
								h3 {
									color: #296cdc;
									font-size: 14px;
									font-weight: 500;
									margin: 10px 0 0 0;
								}

								p {
									color: #aeaeae;
									font-size: 12px;
									margin: 0;
									line-height: 16px;
								}
							}
						}
					}
				}
			}

			.date-and-time-select-wrapper {
				height: 300px;
				width: 880px;
				margin: 0 auto;
				background-color: #fafafa;
				border-radius: 10px;
				display: flex;

				&.disabled {
					opacity: 0.4;
					pointer-events: none;
				}

				.date-select {
					border-right: 1px solid #f2f2f2;
					padding: 0 20px;
					width: 301px;
				}

				.time-select {
					padding: 20px 12px;
					width: 100%;
					overflow: auto;

					.selected-date-wrapper {
						height: 35px;

						p.selected-date {
							font-size: 15px;
							color: #bdbdbd;
							float: left;
							width: 75%;
							margin: 0;
						}

						p.pager {
							font-size: 15px;
							color: #6d6d6d;
							float: right;
							width: 15%;
							margin: 0;

							span {
								color: #bdbdbd;
							}
						}
					}

					.time-selector-wrapper {
						height: 215px;

						.time-tile {
							width: 170px;
							float: left;
							border: 1px solid #e1e1e1;
							height: 30px;
							border-radius: 20px;
							margin: 0 9px 10px 0;
							color: #6d6d6d;
							font-size: 15px;
							padding: 5px 13px;
							line-height: 1.2;
							cursor: pointer;
						}

						.time-tile {
							&.active {
								background-color: #32a1f7;
								color: #fff;
							}

							&.disabled {
								opacity: 0.5;
								cursor: default;
								pointer-events: none;
							}
						}
					}
				}
			}

			.selected-time-wrapper {
				height: auto;
				margin: 10px auto;
				padding-left: 6px;
				width: 880px;

				.inner-time-wrapper {
					height: auto;
					max-height: 140px;
					overflow-y: auto;

					.selected-time-tile {
						// width: 277px;
						border: 1px solid #e5e5e5;
						color: #808080;
						font-size: 10px 13px;
						float: left;
						margin: 0 7px 10px 0;
						padding: 8px 13px;
						border-radius: 20px;
						position: relative;
						font-size: 13px;

						a {
							display: inline-block;
							width: 15px;
							height: 15px;
							background-color: rgba(0, 0, 0, 0);
							background-image: url("../../images/image-sprite-1.png");
							background-position: -41px -198px;
							position: relative;
							top: 3px;
							//right: 8px;
						}
					}
				}
			}

			.message-content-wrapper {
				height: auto;
				background-color: #fafafa;
				padding: 20px 20px 0;
				border-top: 1px solid #f2f2f2;
				margin-top: 50px;
				overflow-y: auto;
				max-height: 150px;

				p {
					font-size: 15px;
					color: #5a5a5a;

					&.msg-content {
						margin-bottom: 10px;
						word-wrap: break-word;
					}
				}
			}

			.message-content-wrapper {
				&.offers {
					background-color: #fff;
					border: 0 none;
					margin-top: 0;
					overflow: unset;
					max-height: inherit;

					.preview-message {
						max-height: 143px;
						overflow-y: auto;
					}
				}
			}

			.attachements {
				margin: 5px 0 20px 0;

				p {
					color: #36a3f7;
					font-size: 13px;
					line-height: 1.22;
					text-decoration: underline;
					max-width: 500px;
					height: 34px;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;

					span {
						background-size: cover;
						display: inline-block;
						height: 15px;
						margin-right: 9px;
						position: relative;
						top: 2px;
						width: 11px;
					}

					.text {
						background-image: url("../../images/text-file.png");
						margin-left: 2px;
					}

					.pdf {
						background-image: url("../../images/pdf-file.png");
						width: 15px;
					}

					.doc {
						background-image: url("../../images/doc-file.png");
						width: 15px;
					}
				}
			}

			.preview-content {
				height: auto;

				&.offer {
					clear: both;
				}

				clear: both;

				.candidates-wrapper {
					padding: 0 25px;
					background-color: #fafafa;
					border-top: 1px solid #f2f2f2;
					border-bottom: 1px solid #f2f2f2;
					height: auto;

					.inner-candidates-wrapper {
						max-height: 140px;
						overflow-y: auto;

						.candidate {
							margin-bottom: 15px;

							.result-details {
								display: flex;

								.profile-picture {
									margin-right: 15px;
									height: 55px;
									position: relative;
									width: 51px;

									&:after {
										background-color: rgba(0, 0, 0, 0);
										background-image: url("../../images/default-profile-picture.jpg");
										background-repeat: no-repeat;
										background-position: center;
										background-size: cover;
										content: "";
										display: block;
										position: absolute;
										top: 0;
										left: 0;
										width: 51px;
										height: 51px;
										border-radius: 5px;
									}

									.img {
										background-size: cover;
										background-repeat: no-repeat;
										border-radius: 5px;
										height: 51px;
										width: 51px;
										position: relative;
										z-index: 1;
									}
								}

								.applier {
									h3 {
										color: #296cdc;
										font-size: 14px;
										font-weight: 500;
										margin: 10px 0 0 0;
									}

									p {
										color: #aeaeae;
										font-size: 12px;
										margin: 0;
										line-height: 16px;
									}
								}
							}
						}
					}

					label {
						width: 100%;
						margin: 15px 0 15px -5px;
					}
				}

				.selected-time-wrapper {
					label {
						width: 100%;
					}

					.selected-time-tile {
						padding: 8px 13px;

						a {
							top: 10px;
						}
					}
				}
			}

			.pop-up-footer {
				border-top: 1px solid #f7f7f7;
				width: 100%;
				clear: both;

				.button-wrapper {
					display: flex;
					float: right;

					button.btn-next,
					button.btn-back {
						float: right;
						background-color: rgba(0, 0, 0, 0);
						margin: 15px 10px 0;
						color: #32a1f7;
					}

					button.btn-next.disabled {
						color: #bdbdbd;
					}

					button.btn-back.disabled {
						display: none;
					}
				}

				button.btn-back {
					background-color: rgba(0, 0, 0, 0);
					color: #32a1f7;
					font-size: 16px;
				}
			}

			.send-message-wrapper {
				float: left;
				padding: 0 20px;
				margin-top: 30px;
				width: 100%;

				.form-group {
					padding-left: 0;
				}

				textarea {
					margin-top: 15px;
				}

				textarea.fade {
					opacity: 0.7;
					pointer-events: none;
				}

				.keyword-details-wrapper {
					background-color: #fafafa;
					border-radius: 5px;
					margin-top: 15px;
					padding: 15px;

					p {
						color: #808080;
					}

					.table-wrapper {
						margin-top: 14px;
						background-color: #fff;
						padding: 20px;
						width: -moz-fit-content;
						width: -webkit-fit-content;

						table {
							//tbody {
							//padding: 5px;

							tr {
								td {
									&.keyword-description {
										color: #808080;
									}

									&:first-child {
										padding-right: 30px;
									}
								}
							}

							//}
						}
					}
				}
			}

			.send-message-wrapper.offers {
				margin-top: 0;
			}

			.send-message-wrapper.offers.fade {
				opacity: 0.3;
				pointer-events: none;
			}

			.wrapper.w-border {
				border-top: 1px solid rgba(0, 0, 0, 0.2);
				padding: 17px 0 0;
			}

			.wrapper {
				float: left;
				padding: 10px 20px 17px;
				width: 100%;

				.content-left {
					width: 134px;

					label {
						float: left;
						margin-top: 9px;
					}

					i {
						background-image: url("../../images/info-gray.png");
						background-repeat: no-repeat;
						background-size: cover;
						cursor: pointer;
						display: block;
						height: 19px;
						margin-left: 6px;
						margin-top: 10px;
						width: 19px;
					}
				}

				.content-right.updated {
					width: calc(100% - 134px);
				}

				.content-right {
					.btn-upload {
						min-width: 173px;
						height: 41px;
						border-radius: 4px;
						background-color: #ffffff;
						border: solid 2px #32a1f7;
						color: #32a1f7;
						font-size: 15.2px;
					}

					.btn-upload.uploading {
						border: solid 2px #b6b6b6;
						color: #b6b6b6;
						position: relative;
						text-align: left;
						min-width: 137px;

						i {
							background-image: url("../../images/refreshing.png");
							background-size: cover;
							display: block;
							height: 20px;
							position: absolute;
							right: 10px;
							top: 9px;
							width: 20px;

							-webkit-animation: rotateSpinner 1.2s linear infinite;
							animation: 1.2s linear 0s normal none infinite running rotateSpinner;
						}
					}

					.btn-upload.edit {
						min-width: auto;
						position: relative;
						text-align: left;
						text-decoration: underline;
						padding-right: 7px;
						margin-right: 5px;
						max-width: 350px;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;

						i {
							background-image: url("../../images/close-blue.png");
							background-size: cover;
							display: block;
							height: 15px;
							position: absolute;
							right: 10px;
							top: 11px;
							width: 15px;
						}
					}

					i.close-new {
						background-image: url("../../images/close-blue.png");
						background-size: cover;
						cursor: pointer;
						display: block;
						float: left;
						height: 15px;
						margin-top: 13px;
						width: 15px;
					}

					.btn-upload.wo-border {
						border: 0 none;
					}

					.custom-file {
						display: block;
						margin: 0;
						position: relative;

						#file {
							cursor: pointer;
							height: 41px;
							left: 0;
							opacity: 0;
							position: absolute;
							top: 0;
							width: 173px;
							z-index: 1;
						}

						#file.fade {
							pointer-events: none;
						}

						#file.fade+.btn-upload {
							opacity: 0.3;
						}
					}
				}
			}
		}

		.pop-up-container.add-new-template {
			width: 570px;
			height: 315px;
			margin-top: 150px;

			.pop-up-content {
				padding: 0 20px;
				height: 180px;
				margin-top: 20px;

				.form-group {
					.form-control {
						width: 80%;
					}

					.input {
						height: 35px;
					}
				}

				.form-group.text-area {
					margin-top: 10px;
				}
			}

			button.btn-save {
				background-color: rgba(0, 0, 0, 0);
				margin: 15px 10px 0;
				color: #32a1f7;
			}
		}

		.select-wrapper {
			display: block;
			height: 33px;
			margin-left: 25px;
			position: relative;
			width: 140px;
			border-radius: 20px;
			background-color: #fff;
			background-color: -webkit-linear-gradient(#fff, #eee);
			background-color: -o-linear-gradient(#fff, #eee);
			background-color: -moz-linear-gradient(#fff, #eee);
			background-color: linear-gradient(#fff, #eee);

			.form-control {
				position: absolute;
				background-color: transparent;
				-webkit-box-sizing: border-box;
				-moz-box-sizing: border-box;
				box-sizing: border-box;
				-webkit-appearance: none;
				-moz-appearance: none;
				background-image: linear-gradient(45deg, transparent 50%, gray 50%),
					linear-gradient(135deg, gray 50%, transparent 50%);
				background-position: calc(100% - 20px) calc(50%),
					calc(100% - 15px) calc(50%), calc(100% - 2.5em) 0.5em;
				background-size: 5px 5px, 5px 5px, 1px 1.5em;
				background-repeat: no-repeat;
				border-color: #e5e5e5;
				box-shadow: none;
				border-radius: 20px;
				top: 0;
				left: 0;
				font-size: 13px;
			}
		}
	}
}

.email-invite {
	.modal-dialog {
		.modal-content {
			min-height: auto !important;
		}
	}
}

.new-talent {
	.modal-dialog {
		.modal-content {
			width: 600px !important;
		}
	}
}

.hire-popup-view-list {
	.modal-dialog {
		.modal-content {

			.ngdialog-body {
				max-height: 392px;
				overflow: scroll;
				padding: 14px 19px 25px;
				background-color: #fafafa;

				.view-list-popup-body-wrapper {
					float: left;
					width: 100%;

					label {
						color: #838383;
						font-size: 13px;
						font-weight: 400;
						width: 75px;
						float: left;
						margin-bottom: 30px;
					}

					#template-title {
						margin-bottom: 20px;
					}

					#template-title,
					#msg-content-area {
						float: right;
						width: 450px;
					}

					#msg-content-area {
						resize: none;
					}

					.view-list-empty-state {
						text-align: center;
						padding-top: 66px;
						padding-bottom: 55px;

						p {
							font-size: 15px;
							line-height: 20px;
							color: #bdbdbd;
							margin: 0;
						}

						button {
							margin-top: 30px;
						}
					}

					ul.view-list-popup-ul {
						list-style: none;
						padding: 0;
						margin: 0;

						li {
							padding: 10px;
							margin-bottom: 15px;
							border-radius: 4px;
							background-color: #ffffff;
							display: inline-block;
							width: 100%;

							.user-profile-picture {
								.img {
									background-size: cover;
									background-position: center;
									width: 60px;
									height: 60px;
									border-radius: 4px;
									position: relative;
									z-index: 1;
								}

								display: inline-block;
								float: left;
								position: relative;

								&:after {
									background-color: rgba(0, 0, 0, 0);
									background-image: url("../../images/default-profile-picture.jpg");
									background-repeat: no-repeat;
									background-position: center;
									background-size: cover;
									content: "";
									display: block;
									position: absolute;
									top: 0;
									left: 0;
									width: 60px;
									height: 60px;
									border-radius: 4px;
								}
							}

							.user-profile-info {
								margin-left: 85px;

								.user-profile-line-1 {
									position: relative;
								}

								.user-profile-fname {
									font-size: 16px;
									color: $primary_color_blue;
									height: 21px;
									width: 390px;
									display: block;
									overflow: hidden;
									white-space: nowrap;
									text-overflow: ellipsis;
								}

								.user-profile-location-ui-dash,
								.user-profile-location,
								.user-profile-job {
									font-size: 14px;
									color: $text_color_gray_1;
								}

								.user-action-buttons {
									button {
										float: left;
										margin-left: 5px;
										box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.08);
									}
								}
							}
						}

						li:last-child {
							margin-bottom: 0;
						}
					}
				}
			}

			.modal-footer {
				height: 58px;
				padding: 15px 12px;
			}
		}
	}
}

.hire-popup-create-list {
	.modal-dialog {
		.modal-content {

			.ngdialog-body {
				padding: 14px 19px 25px;
				background-color: #fafafa;
				.create-list-popup-body-wrapper {
					input {
						height: 44px;
						border: 1px solid #d0d0d0;
						box-shadow: none;
						font-size: 16px;
						font-weight: 400;
					}
				}
			}

			.modal-footer {
				height: 58px;
				padding: 15px 12px;
			}
		}
	}
}

.hire-alert-popup {
	.modal-dialog {
		width: 370px;

		.modal-content {

			.modal-header {
				.modal-title {
					font-size: 19px;
				}
			}

			.modal-body {
				padding: 14px 16px 20px;
				background-color: white;

				p {
					font-size: 16px;
				}
			}

			button {
				color: #2b6edc;
				box-shadow: none;
			}
		}
	}
}

.delete-list-confirmation {
	.modal-dialog {
		width: 450px;

		.modal-content {
			background-color: #ffffff;
			.modal-body {
				padding: 15px;
			}
		}
	}
}

.profile-video {
	.modal-dialog {
		width: 353px;

		.modal-content {
			height: 440px;

			.modal-header {
				height: auto !important;
				padding: 0px !important;
				border: none !important;

				.close {
					margin: 2px 7px 0 0 !important;
				}
			}

			.modal-body {
				padding: 0px;

				.pop-up-content {
					line-height: 1.2;

					.resumeVideoItemPanel {
						position: relative;

						video {
							width: 351px;
							height: 357px;
						}

						span.play {
							background-color: rgba(0, 0, 0, 0);
							background-image: url("../../images/image-sprite-1.png");
							background-repeat: no-repeat;
							background-position: -1px -295px;
							height: 93px;
							left: 127px;
							position: absolute;
							top: 125px;
							width: 93px;
						}
					}
				}

				.pop-up-content.main {
					.inner-details {
						padding: 0 20px;

						.profile-picture {
							float: left;
							margin-right: 20px;

							img {
								border-radius: 50%;
							}
						}

						.basic-details {
							float: left;

							.profile-name {
								font-size: 12px;
								color: #303030;
								margin: 0;
							}

							.occupation {
								font-size: 12px;
								color: #303030;
								margin: 0;
							}

							.location {
								font-size: 9px;
								color: #b7b7b7;
								margin: 0 0 0 16px;
								position: relative;

								.icon-navigation {
									width: 10px;
									height: 10px;
									position: absolute;
									top: 6px;
									left: -16px;
									background-position: -85px -200px;
								}
							}
						}
					}
				}

				.pop-up-content.section {
					.section-header {
						padding: 4px 20px 0;
						float: left;
						width: 100%;

						.section-icon {
							float: left;
							margin-right: 20px;
							position: relative;

							height: 43px;
							width: 43px;

							&:after {
								background-image: url("../../images/default-profile-picture.jpg");
								background-position: center;
								background-size: cover;
								border-radius: 50%;
								content: "";
								display: block;
								height: 43px;
								left: 0;
								position: absolute;
								top: 0;
								width: 43px;
							}

							.img {
								background-size: cover;
								border-radius: 50%;
								height: 43px;
								width: 43px;

								position: relative;
								z-index: 1;
							}
						}

						.header-title-wrapper {
							float: left;

							.time-period {
								font-size: 7px;
								color: #303030;
								margin: 0;

								&.blur {
									filter: blur(0.2rem);
								}
							}

							.position {
								font-size: 11px;
								color: #303030;
								margin: 0;
							}

							.company {
								font-size: 11px;
								color: #303030;
								margin: 0;
							}

							.subtitle-one {
								font-size: 8px;
								color: #b7b7b7;
								margin: 0;
							}
						}
					}
				}
			}
		}
	}
}

.hire-popup-template-list {
	.modal-dialog {
		.modal-content {

			.modal-body {
				padding: 14px 19px 25px;
				background-color: #fafafa;
				height: 186px;

				.view-list-popup-body-wrapper {
					float: left;
					width: 100%;

					label {
						color: #838383;
						font-size: 13px;
						font-weight: 400;
						width: 75px;
						float: left;
						margin-bottom: 30px;
					}

					#template-title {
						margin-bottom: 20px;
					}

					#template-title,
					#msg-content-area {
						float: right;
						width: 450px;
					}

					#msg-content-area {
						resize: none;
					}
				}
			}
		}
	}
}

.right-info {
	.modal-dialog {
		width: 945px;

		.modal-content {
			label {
				color: #838383;
				font-size: 12px;
				font-weight: 400;
				height: 34px;
				line-height: 29px;
				margin-right: 30px;
				width: 100px;
			}

			.item-details-wrapper {
				padding: 20px 20px 0;
				height: 75px;
				border-bottom: 1px solid #f7f7f7;
				display: flex;

				.job-details {
					display: flex;

					p {
						color: #303030;
						font-size: 17px;
						font-weight: 400;
						margin: 3px 0 0 0;
					}
				}

				.selected-time {
					max-height: 149px;
					/* Madhu added max-width below */
					max-width: 750px;
					overflow-y: auto;

					.time {
						color: #555151;
						font-size: 17px;
						font-weight: 400;
						margin: 0;
					}

					.remaining-time {
						color: #bdbdbd;
						font-size: 15px;
						margin-bottom: 5px;
					}

					.selected-time-tile {
						padding: 8px 9px;
						border: 1px solid #e5e5e5;
						border-radius: 20px;
						color: #808080;
						float: left;
						margin: 0 10px 10px 0;
						position: relative;
						width: 242px;
						font-size: 12px;
					}
				}

				.add-to-calendar {
					right: -14px;
					height: 35px;
					font-size: 15px;
					font-weight: 700;
					color: #317eff;
					border-radius: 1px;
					position: relative;
					background-color: #ffffff;
					padding: 6px 15px 10px 16px;
					border: solid 0.6px rgba(0, 0, 0, 0.12);
					box-shadow: 0 1px 3px 0 rgba(169, 169, 169, 0.2);
					cursor: pointer;
					float: left;

					svg {
						float: left;
						margin-top: 3px;
					}

					.dropdown-toggle {
						&:after {
							display: none;
						}

						.btn-text {
							display: inline-block;
							width: 118px;
							margin-left: 5px;
						}
					}

					.dropdown-menu {
						background: #ffffff;
						top: 36px;
						margin: 4px -20px -9px -16px;
						width: 166px;
						text-align: center;

						&:before {
							display: none;
						}

						.dropdown-item {
							padding: 7px 15px;
							margin-bottom: 0;
							background: 0 0;
							outline: 0;
							display: block;
							border: none;
							width: 100%;
							cursor: pointer;
							font-size: 13px;
							color: rgba(0, 0, 0, .7);
							transition: .5s;

							&:hover {
								background: rgba(0, 0, 0, 0.1);
								border-radius: 0;
								text-decoration: none;
							}
						}
					}
				}

				.message-wrapper {
					height: auto;
					margin-left: 9px;
					overflow-y: auto;
					max-height: 143px;
					width: 100%;
				}

				.time-lable,
				.requested-by,
				.message {
					margin-left: 15px;
				}

				.requester {
					color: #555151;
					font-size: 17px;
					font-weight: 400;
					margin: 5px 0 0;
				}
			}

			.item-details-wrapper.message-wrapper,
			.item-details-wrapper.reschedule-request-wrapper {
				height: auto;
				padding-bottom: 25px;
			}

			.item-details-wrapper.time-slots {
				height: auto;
				padding-bottom: 10px;
			}

			.item-details-wrapper.interview-type {
				.message-wrapper {
					p {
						margin-top: 5px;
					}
				}
			}

			.item-details-wrapper.meeting-url {
				.message-wrapper {
					a {
						color: #32a1f7;
						cursor: pointer;
						margin-top: 5px;

						&:hover {
							text-decoration: underline;
						}
					}
				}
			}

			.item-details-wrapper.offers {
				display: block;
				float: left;
				height: auto;
				width: 100%;

				.message-wrapper {
					float: left;
					width: calc(100% - 165px);
					margin-left: 0;

					p {
						word-wrap: break-word;
					}
				}

				.attachements {
					margin: 5px 0 0 145px;

					p {
						color: #36a3f7;
						font-size: 13px;
						line-height: 1.22;
						text-decoration: underline;

						span {
							background-size: cover;
							display: inline-block;
							height: 15px;
							margin-right: 9px;
							position: relative;
							top: 2px;
							width: 11px;
						}

						.text {
							background-image: url("../../images/text-file.png");
							margin-left: 2px;
						}

						.pdf {
							background-image: url("../../images/pdf-file.png");
							width: 15px;
						}

						.doc {
							background-image: url("../../images/doc-file.png");
							width: 15px;
						}
					}
				}
			}
		}
	}
}

.cancel-info {
	.modal-dialog {
		width: 390px;

		.modal-content {
			.modal-body {
				padding: 15px !important;
			}

			.cancel-title {
				font-size: 16px;
				color: #5a5a5a;
				margin: 0;
			}

			.cancel-text {
				font-size: 13px;
				color: #808080;
			}

			.button-wrapper {
				float: right;
				margin-bottom: 5px;

				button {
					margin-left: 5px;
					background: none;
					color: #32a1f7;

					&:focus {
						outline: 1px solid;
						border-radius: 2px;
					}
				}
			}
		}
	}
}

.createJob {

	.modal-dialog {
		width: 882px;

		.modal-content {
			width: 100%;

			.modal-header {
				height: 47px;
				padding: 15px 16px;
				width: 100%;
				border-bottom: solid 0.6px rgba(0, 0, 0, 0.12);

				.modal-title,
				.close {
					color: #333;
					font-size: 16px;
					font-weight: 500;
					line-height: 1;
					margin: 0;

				}

				.modal-title {
					width: 500px;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
				}

				.close {
					margin-top: -18px;
					line-height: 20px;
				}
			}

			.modal-body {
				min-height: 495px;
				padding: 0px !important;

				span.error {
					display: block;
					color: #f00;
					font-size: 13px;
					float: left;
				}

				.create-job-form {
					width: 100%;
					padding-top: 13px;

					.form-group {
						&.title {
							padding-right: 5px;
						}

						&.location {
							padding-left: 5px;
						}

						&.closing-date-wrapper {
							padding-left: 15px;
							width: 195px;
							position: relative;
						}

						&.salary-range-wrapper {
							width: 435px;
							padding-right: 15px;

							.salary-start,
							.salary-end {
								width: 150px;
							}

							.salary-rate {
								margin-top: 1px;
								margin-left: 5px;
								width: 96px;
								height: 30px;
								border-radius: 15px;
								border: solid 1px #dcdcdc;
								box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
								background-image: linear-gradient(to bottom, #ffffff, #e2e0e0);
								/* Madhu reduced pading from 6 to 1 */
								padding: 0 1px;

								.form-control {
									border: none;
									background: none;
									height: 30px;
								}
							}
						}
					}

					label.control-label {
						font-size: 12px;
						line-height: 14px;
						font-weight: 500;
						color: #303030;
					}

					input.form-control {
						border: solid 1px rgba(0, 0, 0, 0.12);
						border-radius: 4px;

						&.form-control[readonly] {
							background-color: #fff;
							cursor: default !important;
						}
					}

					.checkboxWrapper {
						margin-right: 0;
					}

					.checkboxWrapper>span {
						width: auto;
					}

					.job-term-wrapper {
						width: 215px;

						.checkboxWrapper {
							width: 50%;

							span {}
						}
					}

					.job-type-wrapper {
						margin-left: 15px;
						width: 321px;

						.checkboxWrapper {
							width: 33%;

							span {}
						}
					}

					.job-term {
						padding: 10px;
						border-radius: 5px;
						background-color: rgba(0, 0, 0, 0.03);
						float: left;
						width: 100%;

						label.control-label {
							margin: 0;
						}

						&.checkbox-wrap {
							.checkboxWrapper {
								span {
									line-height: 15px;
								}
							}
						}
					}

					.job-post-options {
						border-top: solid 0.6px rgba(0, 0, 0, 0.12);
						border-bottom: solid 0.6px rgba(0, 0, 0, 0.12);

						.checkboxWrapper {
							margin-top: 20px;
							margin-bottom: 15px;

							span {
								line-height: 15px;
							}

							span.info {
								background-image: url("../../images/info.png");
								background-size: cover;
								background-repeat: no-repeat;
								height: 16px;
								position: absolute;
								right: -23px;
								top: 0;
								width: 16px !important;
							}
						}
					}

					.job-description {
						.job-des-ai {
						display: flex;
            align-items: center;
						h5 {
							// margin-top: 0;
							// margin-bottom: 11px;
							font-size: 14px;
							font-weight: 500;
							color: #303030;
						}
						button {
							color: #fff;
							margin-left: auto;
							background-color: #289ffa;
						}
						button[disabled] {
							background-color: #bdbdbd !important;
							color: #ffffff;  
						//	border-radius: 5px 0 0 5px;         
						}
					}
				}

					.job-questions {
						font-size: 14px;
						color: rgba(0, 0, 0, 0.4);
						line-height: 1.57;

						.questins-section-description {
							margin-bottom: 19px;
						}

						.manage-questions {
							.manage-questions-wrapper {
								padding: 0 20px 0;
								width: 100%;
								border-radius: 5px;
								background-color: rgba(0, 0, 0, 0.03);

								.questions-action {
									padding: 20px 0;
									width: 100%;

									&:first-child {
										border-bottom: solid 0.6px rgba(0, 0, 0, 0.12);
									}

									label {
										color: #303030;
										font-size: 16px;
										margin: 0;
										font-weight: 500;
									}

									p {
										margin: 0;
									}

									.ui-actions {
										.select-question-types {
											button.btn {
												width: 167px;
												height: 42px;
												border-radius: 5px;
												background-color: #289ffa;
												box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
												font-size: 16px;
												font-weight: 500;
												color: #ffffff;
											}

											.dropdown-menu {
												overflow: hidden;
												margin-top: 8px;
												border-radius: 4px;
												border: solid 0.6px #dcdcdc;
												padding-bottom: 0;

												li {
													border-bottom: solid 0.6px rgba(0, 0, 0, 0.12);

													a {
														padding: 8px 16px;
														font-size: 13px;
														color: rgba(0, 0, 0, 0.87);
													}

													&:last-child {
														border: none;
													}

													&.select-type {
														padding: 3px 8px 5px;
														font-size: 11px;
														color: rgba(0, 0, 0, 0.4);
														font-style: italic;
														line-height: 11px;
														height: 21px;
													}
												}
											}
										}

										.unnanu-select-wrapper {
											width: 176px;
										}

										.select-job-post-dropdown {
											float: left;
											top: 100%;
											position: absolute;
											margin-top: 7px;
											width: 336px;
											border-radius: 4px;
											box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
											background-color: #ffffff;
											border: solid 0.6px #dcdcdc;
											left: auto;
											right: 0;
											padding: 0;

											.search-jobs-wrapper {
												padding: 10px 10px 3px;
												position: relative;
												float: left;
												width: 100%;

												.magnifying-glass {
													position: absolute;
													top: 20px;
													left: 20px;
													width: 16px;
													height: 16px;
													z-index: 2;
												}

												input {
													padding: 10px 10px 10px 34px;
													color: rgba(0, 0, 0, 0.4);
													line-height: 16px;
													font-size: 14px;
													height: 35px;
												}
											}

											.previous-questions-list {
												padding: 0;
												margin: 0;
												max-height: 263px;
												list-style: none;
												float: left;
												width: 100%;
												overflow: scroll;

												li {
													cursor: pointer;
													float: left;
													width: 100%;
													padding: 8px 15px;
													border-bottom: solid 1px rgba(0, 0, 0, 0.12);

													.text-wrapper {
														width: 240px;
														overflow: hidden;

														h5 {
															height: 15px;
															overflow: hidden;
															max-width: 240px;
															text-overflow: ellipsis;
															white-space: nowrap;
															font-size: 13px;
															line-height: 15px;
															color: rgba(48, 48, 48, 0.87);
															font-weight: 500;
															margin-top: 0;
															margin-bottom: 5px;
														}

														p {
															margin: 0;
															font-size: 13px;
															line-height: 15px;
															color: rgba(0, 0, 0, 0.4);
														}
													}

													&:first-child {
														padding-top: 11px;
													}
												}
											}

											.count-wrapper {
												.count {
													text-align: center;
													line-height: 20px;
													min-width: 21px;
													height: 21px;
													border-radius: 4.5px;
													background-color: rgba(0, 0, 0, 0.12);
												}
											}

											.view-all-questions {
												cursor: pointer;
												line-height: 15px;
												float: left;
												width: 100%;
												height: 37px;
												padding: 10px 15px 12px;
												font-size: 13px;
												font-weight: 500;
												color: rgba(48, 48, 48, 0.87);
											}

											.empty-case-import-questions {
												padding: 29px 18px;
												width: 100%;
												color: rgba(0, 0, 0, 0.4);
											}
										}
									}
								}
							}
						}

						.questions-list-view {
							margin-top: 15px;
							padding-top: 13px;
							height: 340px;
							border-top: solid 0.6px rgba(0, 0, 0, 0.12);
							position: relative;

							.sortable-container {
								width: auto !important;
								border: none;

								.sortable-list {
									height: 325px !important;
								}
							}

							.questions-list-view-empty-case {
								text-align: center;
								font-size: 14px;
								color: rgba(0, 0, 0, 0.4);
								line-height: 1.57;
								position: absolute;
								left: 50%;
								top: 50%;
								transform: translate(-50%, -50%);
							}

							.single-question-wrapper {
								width: 100%;
								float: left;
								padding: 20px 19px 16px;
								height: 80px;
								border-radius: 5px;
								box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.12);
								background-color: #ffffff;
								border: solid 0.6px rgba(0, 0, 0, 0.12);
								margin-bottom: 10px;

								.question-drag-handler {
									margin-top: 12px;
								}

								.drag-breadcrumb {
									padding: 0;
									border: none;
									box-shadow: none;
									width: 25px;
									height: 18px;
									cursor: move;

									span.icon-bar {
										background-color: #888;
										display: block;
										width: 100%;
										height: 2px;
										border-radius: 1px;
										margin-bottom: 5px;

										&:last-child {
											margin: 0;
										}
									}
								}

								.question-text-content {
									margin-left: 20px;

									label,
									p {
										cursor: pointer;
										margin: 0;
									}

									label {
										font-size: 16px;
										font-weight: 500;
										color: #303030;
										line-height: 1.38;
									}

									p {
										color: rgba(0, 0, 0, 0.4);
										line-height: 1.57;
										font-size: 14px;
										max-width: 660px;
										overflow: hidden;
										text-overflow: ellipsis;
										white-space: nowrap;
									}
								}

								.question-actions-ui {
									margin-right: 9px;
									margin-top: 9px;

									button {
										padding: 0;
										margin-left: 27px;
										border: none;
										background: none;
										box-shadow: none;

										&:first-child {
											margin: 0;
										}
									}
								}
							}
						}
					}
				}
			}

		}
	}
}

.addQuestion {

	.modal-dialog {
		width: 642px;

		.modal-content {
			width: 100%;

			span.error {
				display: block;
				width: 100%;
				float: left;
				color: #f00;
			}

			.body-content {
				padding: 16px 0 20px;
			}

			.popup-footer {
				.checkboxWrapper {
					margin: 0;

					label {
						margin-top: 2px;
					}
				}
			}

		}
	}
}

.addQuestion {

	.modal-dialog {
		width: 642px;

		.modal-content {
			width: 100%;

			h3 {
				font-size: 18px;
				color: #5a5a5a;
				margin-top: 0;
			}

			p {
				font-size: 14px;
				color: #808080;
			}

			.questions-manage-form {
				label {
					font-size: 12px;
					font-weight: 500;
					color: #303030;
					line-height: 14px;
					margin-bottom: 7px;
				}

				p {
					line-height: 16px;
					margin: 0 0 10px;
					font-size: 14px;
					color: rgba(0, 0, 0, 0.4);
				}

				.question-input-wrapper {
					margin-bottom: 0;

					input {
						background-color: #ffffff;
						border: solid 1px rgba(0, 0, 0, 0.12);
					}
				}

				.question-file-upload {
					margin-top: 10px;

					.question-file-upload-label {
						width: 282px;
						font-size: 14px;
						line-height: 1.71;
						color: rgba(0, 0, 0, 0.4);
						float: left;
						margin-right: 8px;
					}

					.question-file-upload-ui {
						float: left;
						width: 320px;

						.custom-file {
							display: block;
							margin: 0;
							position: relative;
							float: right;

							.custom-file-input {
								cursor: pointer;
								height: 41px;
								left: 0;
								opacity: 0;
								position: absolute;
								top: 0;
								width: 173px;
								z-index: 1;
							}
						}

						.btn-upload {
							min-width: 173px;
							height: 41px;
							border-radius: 4px;
							background-color: #ffffff;
							border: solid 2px #32a1f7;
							color: #32a1f7;
							font-size: 15.2px;

							&.uploading {
								border: solid 2px #b6b6b6;
								color: #b6b6b6;
								position: relative;
								text-align: left;
								width: 137px;

								i {
									background-image: url(../../images/refreshing.png);
									background-size: cover;
									display: block;
									height: 20px;
									position: absolute;
									right: 10px;
									top: 9px;
									width: 20px;
									-webkit-animation: rotateSpinner 1.2s linear infinite;
									animation: 1.2s linear 0s normal none infinite running rotateSpinner;
								}
							}

							&.edit {
								min-width: auto;
								position: relative;
								text-align: left;
								text-decoration: underline;
								padding-right: 35px;
								width: 100%;
								overflow: hidden;
								text-overflow: ellipsis;
								white-space: nowrap;
							}
						}

						i.close-new {
							background-image: url(../../images/close-blue.png);
							background-size: cover;
							cursor: pointer;
							display: block;
							float: left;
							height: 15px;
							margin-top: 13px;
							width: 15px;
							margin-left: -30px;
							z-index: 9;
							position: relative;
						}
					}
				}

				.number-question-answer-wrapper {
					.number-question-answer-dropdown {
						.select-wrapper {
							margin-top: 1px;
							width: 146px;
							height: 30px;
							border-radius: 15px;
							border: solid 1px #dcdcdc;
							box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
							background-image: linear-gradient(to bottom, #ffffff, #e2e0e0);
							padding: 0 0px;

							.form-control {
								border: none;
								background: none;
								height: 30px;
							}
						}
					}

					.number-question-answer-input {
						width: 450px;
						margin-left: 14px;

						.range-start,
						.range-end {
							width: 205px;
						}

						.range-dash {
							width: 38px;
							height: 34px;
							line-height: 34px;
							text-align: center;
						}
					}
				}

				.question-options-view {
					margin-top: 15px;

					.question-instructions {
						margin-bottom: 15px;
					}

					.multiple-choices-list {
						.single-choice-item {
							position: relative;
							width: 100%;
							float: left;
							margin-bottom: 10px;

							&:last-child {
								margin-bottom: 0;
							}

							.input-group {
								width: 100%;

								&.error {
									border: 1px solid #f00 !important;
									border-radius: 4px;
								}

								.yes-no-label-text {
									height: 35px;
									border: solid 1px rgba(0, 0, 0, 0.12);
									border-left: 0;
									box-shadow: none;
								}

								.input-group-addon {
									width: 35px;
									height: 35px;
									border: none;
									position: relative;
									overflow: hidden;

									input {
										position: absolute;
										left: 0;
										top: 0;
										width: 100%;
										height: 100%;
										z-index: 4;
										opacity: 0;
										cursor: pointer;
									}

									span {
										border: solid 1px rgba(0, 0, 0, 0.12);
										position: absolute;
										top: 0;
										right: 0;
										left: 0;
										bottom: 0;
										z-index: 1;
									}

									&:after {
										content: "";
										width: 16px;
										height: 7px;
										content: "";
										position: absolute;
										z-index: 2;
										margin-top: -2px;
										top: 50%;
										left: 50%;
										border: 2px solid #ffffff;
										border-top: none;
										border-right: none;
										background: transparent;
										-webkit-transform: translate(-50%, -50%) rotate(-45deg);
										transform: translate(-50%, -50%) rotate(-45deg);
									}

									input[type="checkbox"]:checked+span {
										background-color: #3eb495;
									}
								}

								input.form-control,
								textarea.form-control {
									height: 35px;
									border: solid 1px rgba(0, 0, 0, 0.12);
									border-left: 0;
									box-shadow: none;
								}
							}
						}

						&.answer-has-delete {
							.input-group {
								width: 532px;
								float: left;
							}

							button.btn.remove-choice {
								position: absolute;
								right: 0;
								margin-left: 5px;
								width: 70px;
								height: 35px;
								padding-right: 0;
								padding-left: 0;
								font-size: 14px;
								font-weight: 500;
								color: #ff5a5a;
								background: none;

								&:disabled {
									background-color: #ffffff !important;
									opacity: 0.5;
									background-color: #ffffff !important;
								}
							}
						}
					}

					button.add-option-button {
						color: #319ef2;
						font-size: 14px;
						font-weight: 500;

						&:disabled {
							background-color: #ffffff !important;
							color: #bdbdbd;
						}
					}
				}

				&.add-question-item {
					.multiple-choice {
						margin-top: 15px;

						.question-instructions {
							label {
								margin-bottom: 6px;
							}
						}

						.multiple-choices-list {
							.single-choice-item {
								width: 100%;
								float: left;
								margin-bottom: 10px;

								.input-group {

									.input-group-addon,
									input.form-control {
										box-shadow: none;
										background-color: #ffffff;
										border-color: rgba(0, 0, 0, 0.12);
									}
								}
							}
						}
					}
				}
			}

			.button-wrapper {
				height: 50px;

				button {
					background-color: rgba(0, 0, 0, 0);
					font-size: 14px;
					font-weight: 500;
					color: #303030;
				}

				button.btn-yes {
					color: #ff3455;
				}
			}

		}
	}
}

.selectQuestions {

	.modal-dialog {
		width: 620px;

		.modal-content {
			width: 100%;

			.body-content {
				float: left;
				width: 100%;
			}

			.previous-questions-list-view {
				height: 455px;
				overflow: scroll;
				float: left;
				width: 100%;
				padding-top: 9px;

				.previous-questions-preloader-wrapper {
					position: relative;
					width: 100%;
					height: 100%;
				}

				.load-more-questions-preloader {
					width: 100%;
					float: left;

					.preloader-container {
						padding-top: 15px;
						height: 70px;
						display: block;
						margin: 0;
					}
				}

				.previous-question-item {
					margin-bottom: 10px;
					width: 100%;
					float: left;
					border-radius: 5px;
					box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.12);
					background-color: #ffffff;
					border: solid 0.6px rgba(0, 0, 0, 0.12);
					padding: 13px 18px;

					.checkboxWrapper {
						width: 30px;
						height: 30px;
						margin-top: 7px;
						position: relative;

						input[type="checkbox"] {
							top: 0;
							left: 0;
							width: 30px;
							height: 30px;

							&:checked+label {
								background-color: #319ef2;
								border: 0;
							}
						}

						label {
							width: 30px;
							height: 30px;
							background-color: #ebebeb;
							border-radius: 30px;
							border: 0;
							border: 0;
							top: 0;
							left: 0;

							&:after {
								width: 13px;
								height: 6px;
								opacity: 1;
								left: 50%;
								top: 50%;
								-webkit-transform: translate(-50%, -50%) rotate(-45deg);
								transform: translate(-50%, -50%) rotate(-45deg);
							}
						}
					}

					.job-texts {
						margin-left: 19px;

						h5 {
							margin: 0;
							font-size: 16px;
							font-weight: 500;
							color: #303030;
							line-height: 1.38;
						}

						p {
							margin: 0;
							font-size: 14px;
							line-height: 1.57;
							color: rgba(0, 0, 0, 0.4);

							max-width: 460px;
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;
						}
					}
				}
			}
		}
	}
}

.someOne {
	.modal-dialog {
		width: 100%;

		.modal-content {
			width: 965px;

			.modal-header {
				height: 51px;
				padding: 14px 18px;

				.close {
					margin: 0px 5px 0 0;
				}
			}

			.onboard-wrapper {
				margin-top: -5px;

				.onboard-tab-list {
					list-style: none;
					margin-left: 15px;
					margin-bottom: 0px;
					position: relative;
					z-index: 1;

					li {
						float: left;
						font-size: 20px;
						font-weight: 450;
						padding: 11px 20px 5px 14px;
						display: block;
						position: relative;
						-webkit-perspective: 150px;
						perspective: 150px;
						text-align: center;
						margin-right: 2px;

					}

					li.active {
						cursor: default;

						&:before {
							background-color: #fff;
							content: "";
							display: block;
							padding: 20px 0 26px 0;
							position: absolute;
							border: 0.6px solid rgba(0, 0, 0, 0.2);
							-webkit-transform: rotateX(45deg);
							transform: rotateX(45deg);
							width: 93%;
							height: 30px;
							top: 0;
							left: 0;
							border-radius: 10px 10px 1px 1px;
							z-index: -1;
							border-bottom: 0;
							font-size: 12px;
							color: #5a5a5a;
							font-weight: 500;
						}
					}

					li.active.safari {
						&:before {
							background-color: rgba(0, 0, 0, 0);
						}
					}
				}
			}

			.interview-list-wrapper {
				border: none;
			}

			.scroll-content {
				background-color: #f1f1f1;
				max-height: 520px;
				overflow: auto;
				width: 100%;

				.item-wrapper {
					background-color: #fff;
					float: left;
					margin-bottom: 10px;
					padding: 16px 34px 20px;
					width: 100%;

					&:last-child {
						margin-bottom: 0;
					}

					h3 {
						font-size: 16px;
						font-weight: 700;
						// line-height: 1.5;
						letter-spacing: 0.4px;
						color: #222222;
						margin: 0;
						height: 24px;
						max-width: 350px;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
					}

					p {
						float: left;
						// margin-top: 10px;
						// margin-bottom: 15px;
						word-wrap: break-word;
						width: 100%;
						font-size: 16px;
						// line-height: 1.5;
						letter-spacing: 0.4px;
						color: #777777;
					}

					.status {
						color: #ffffff;
						font-size: 13px;
						font-weight: 500;
						float: right;
						height: 26px;
						line-height: 26px;
						margin-left: 9px;
						padding: 0 8px;
						letter-spacing: 0.3px;
						width: -moz-fit-content;
						width: fit-content;
					}

					.status.waiting {
						background-color: #f4bd3a;
					}

					.status.sent {
						background-color: #4bc082;
					}

					.status.received {
						background-color: #36a3f7;
					}

					.status.decline {
						background-color: #ff2424;
					}

					.msg-wrapper {
						float: left;
						width: 100%;
						max-height: 25px;
						margin-bottom: 5px;
						overflow-y: hidden;
						overflow-x: hidden;
					}

					.button-wrapper {
						float: left;
						width: 100%;

						.btn-send {
							background-color: #34a2f7;
							color: #ffffff;
							font-size: 15.3px;

							&:disabled {
								background-color: rgba(54, 163, 247, 0.2) !important;
								color: rgba(54, 163, 247, 0.26);
							}

							input {
								display: block;
								height: 29px;
								left: 0;
								opacity: 0;
								padding: 19px;
								position: absolute;
								top: -2px;
								width: 100%;
							}
						}
					}

					.due-date {
						float: right;
						margin-top: 10px;
						font-size: 14px;
						letter-spacing: 0.4px;
						color: #868686;
					}

					.attachment-wrapper {
						border-top: 1px solid rgba(#000, 0.05);
						float: left;
						// margin-top: 20px;
						padding-top: 0;
						width: 100%;

						.attachment-item {
							border-bottom: 1px solid rgba(#000, 0.05);
							float: left;
							width: 100%;

							&:last-child {
								border: 0 none;
							}

							p {
								font-size: 13px;
								line-height: 1.22;
								margin-top: 4px;

								a {
									color: #36a3f7;
									font-size: 13px;
									line-height: 1.22;
									margin: 0;
								}

								span {
									background-size: cover;
									display: inline-block;
									height: 23px;
									margin-right: 11px;
									position: relative;
									top: 7px;
									width: 17px;
								}

								select {
									font-size: 16px;
									font-weight: 500;
									margin-top: 10px;
									margin-right: 20px;
									position: relative;
									float: right;
									max-width: 250px;
									color: #36a3f7;
								}

								duedate {
									float: right;
									margin-top: 10px;
									font-size: 16px;
									font-weight: 500;
									letter-spacing: 0.4px;
									color: #36a3f7;
								}

								.text {
									background-image: url("../../images/text-file.png");
									margin-left: 2px;
								}

								.pdf {
									background-image: url("../../images/pdf-file.png");
									width: 21px;
								}

								.doc {
									background-image: url("../../images/doc-file.png");
									width: 21px;
								}
							}
						}
					}
				}
			}

			.pop-up-content {
				padding: 0;
				min-height: 500px;

				.pop-up-left {
					border-right: 1px solid #f5f5f5;
					float: left;

					.scroll-wrapper {
						float: left;
						max-height: 388px;
						overflow: auto;
					}

					.wrapper.shadow {
						box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
					}

					.wrapper {
						border-bottom: 1px solid #f5f5f5;
						padding: 15px 20px 13px;

						input[type="checkbox"] {
							visibility: visible;
							opacity: 0;
							cursor: pointer;
							z-index: 9;

							&:focus+label {
								border-radius: 2px;
							}
						}

						label {
							margin-top: 3px;
						}

						.checkboxWrapper {
							span {
								margin-top: 2px;
								width: auto;
							}

							.select-package-label {
								margin-left: 7px !important;
							}
						}

						input[type="checkbox"]:checked+label {
							background-color: #296cdc;
							border: 2px solid #296cdc;
						}

						p {
							color: #5a5a5a;
							font-size: 15px;
							line-height: 1.47;
							margin: 0;
						}

						.attachment-content {
							.remove-wrap {
								float: left;
								width: 100%;

								a {
									color: #32a1f7;
									font-size: 13px;
									font-weight: 500;
									float: right;
									margin-bottom: 15px;
									margin-right: 18px;

									span {
										background-image: url("../../images/error.png");
										background-size: cover;
										display: inline-block;
										height: 19px;
										width: 19px;
										position: relative;
										top: 4px;
									}
								}
							}

							.wrapper {
								border: 0 none;
								padding: 0;

								.content-right {
									width: calc(100% - 134px);

									.btn-upload.uploading {
										border: solid 2px #b6b6b6;
										color: #b6b6b6;
										position: relative;
										text-align: left;
										width: 137px;

										i {
											background-image: url("../../images/refreshing.png");
											background-size: cover;
											display: block;
											height: 20px;
											position: absolute;
											right: 10px;
											top: 9px;
											width: 20px;
											-webkit-animation: rotateSpinner 1.2s linear infinite;
											animation: 1.2s linear 0s normal none infinite running rotateSpinner;
										}
									}

									.form-group {
										textarea.form-control {
											height: 129px;
										}
									}
								}
							}
						}
					}

					.wrapper.fade {
						opacity: 0.5;
						pointer-events: none;
					}

					.onboard-footer {
						float: left;
						height: 52px;
						padding: 17px 18px 22px;
						width: 100%;

						a {
							color: #32a1f7;
							font-size: 15.2px;
						}
					}

					.onboard-footer.fade {
						opacity: 0.5;
						pointer-events: none;
					}
				}

				.pop-up-right {
					// float: left;
					padding: 15px 19px;
					/* Madhu chaged width from 325 to 460 */
					//width: 460px;

					h3 {
						color: #5a5a5a;
						font-size: 18px;
						font-weight: 300;
						margin: 0 0 18px 0;
					}

					.right-content-wrapper {
						/* Madhu chaged max-height from 372 to 460 */
						max-height: 460px;
						overflow: auto;

						.attachements-wrapper {
							border: solid 1px rgba(182, 182, 182, 0.26);
							border-radius: 4px;
							height: auto;
							margin-bottom: 13px;
							padding: 13px 14px;

							.readmore-wrapper {
								p {
									color: #5a5a5a;
									font-size: 15px;
									line-height: 1.47;
									margin: 0;
								}

								p.time {
									color: #5a5a5a;
									font-size: 12px;
									line-height: 1.83;
								}

								.attachements {
									margin-bottom: 0;
									margin-top: 5px;
									/* Madhu added line below */
									overflow: hidden;

									.single-attachment {
										border-bottom: solid 0.6px rgba(0, 0, 0, 0.12);
										padding-bottom: 10px;
										margin-bottom: 6px;

										&:last-child {
											border: 0;
											margin-bottom: 0;
											padding-bottom: 0;
										}

										.expiry-date {
											font-size: 12px;
											color: #5a5a5a;
										}
									}

									a {
										font-size: 13px;
									}

									p {
										color: #36a3f7;
										font-size: 13px;
										line-height: 1.22;
										margin-bottom: 7px;
									}
								}
							}

							.readmore-wrapper.less {}

							.readmore {
								color: #32a1f7;
								font-size: 12px;
								line-height: 1.83;
								margin-right: 5px;
								margin-top: 5px;

								span {
									background-image: url("../../images/add-job.png");
									background-size: cover;
									display: inline-block;
									height: 9px;
									width: 5px;
									transform: rotate(90deg);
									position: relative;
									top: 1px;
									left: 2px;
								}
							}

							.readmore.less {
								span {
									transform: rotate(-90deg);
								}
							}
						}
					}

					.right-empty {
						i {
							background-image: url("../../images/notifi-empty.png");
							background-size: cover;
							display: block;
							height: 57px;
							margin: 40px auto 23px;
							width: 55px;
						}

						p {
							color: rgba(90, 90, 90, 0.4);
							font-size: 15px;
							line-height: 1.47;
							text-align: center;
						}
					}
				}
			}
		}
	}
}

.newOnboard {

	.modal-dialog {
		width: 642px;

		.modal-content {
			width: 100%;

			.modal-header {
				border-bottom: 1px solid rgba(#000, 0.1);
				margin: 0;
				padding: 20px 18px 13px 18px;
				width: 100%;

				.modal-title {
					color: #5a5a5a;
					font-size: 18px;
					font-weight: 500;
				}

				.close {
					float: right;
					font-size: 15px;
					font-weight: 500;
					margin: -21px 5px 0 0;

					&:hover,
					&:focus {
						text-decoration: none;
					}
				}
			}

			.col-xs-12 {
				padding: 0;
			}

			.onboard-content {
				float: left;
				width: 100%;

				.pckg-name {
					box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
					float: left;
					padding: 16px 18px;
					width: 100%;
				}

				.attachment-content-wrapper {
					float: left;
					max-height: 962px;
					overflow: auto;
					width: 100%;
				}

				.attachment-content {
					box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
					float: left;
					padding: 18px 0 0;
					width: 100%;

					&:last-child {
						box-shadow: 0 0 0;
					}

					.remove-wrap {
						float: left;
						width: 100%;

						a {
							color: #32a1f7;
							font-size: 13px;
							font-weight: 500;
							float: right;
							margin-bottom: 15px;
							margin-right: 18px;

							span {
								background-image: url("../../images/error.png");
								background-size: cover;
								display: inline-block;
								height: 19px;
								width: 19px;
								position: relative;
								top: 4px;
							}
						}
					}

					.wrapper {
						float: left;
						padding: 0 18px 18px;
						width: 100%;
					}

					.wrapper.w-border {
						border-top: 1px solid rgba(#000, 0.2);
						padding: 17px 0 0;
					}
				}

				.content-left {
					width: 134px;

					label {
						color: #5a5a5a;
						font-size: 15px;
						font-weight: 400;
						line-height: 1.47;
						margin: 7px 0 0;
					}
				}

				.content-right {
					width: calc(100% - 134px);

					.form-group {
						margin-bottom: 0;

						.form-control {
							border: solid 1px #bcbcbc;
							border-radius: 4px;
							height: 42px;
						}

						textarea.form-control {
							height: 126px;
						}
					}

					.btn-upload {
						min-width: 173px;
						height: 41px;
						border-radius: 4px;
						background-color: #ffffff;
						border: solid 2px #32a1f7;

						color: #32a1f7;
						font-size: 15.2px;
					}

					.custom-file {
						display: block;
						margin: 0;
						position: relative;

						#file {
							cursor: pointer;
							height: 41px;
							left: 0;
							opacity: 0;
							position: absolute;
							top: 0;
							width: 173px;
							z-index: 1;
						}
					}

					.custom-file.uploading {
						pointer-events: none;
					}

					.btn-upload.wo-border {
						border: 0 none;
					}

					.btn-upload.edit {
						min-width: auto;
						position: relative;
						text-align: left;
						text-decoration: underline;
						padding-right: 7px;
						margin-right: 5px;
						max-width: 390px;
						overflow: hidden;
						text-overflow: ellipsis;

						i {
							background-image: url("../../images/close-blue.png");
							background-size: cover;
							display: block;
							height: 15px;
							position: absolute;
							right: 10px;
							top: 11px;
							width: 15px;
						}
					}

					i.close-new {
						background-image: url("../../images/close-blue.png");
						background-size: cover;
						cursor: pointer;
						display: block;
						float: left;
						height: 15px;
						margin-top: 13px;
						width: 15px;
					}

					.btn-upload.uploading {
						border: solid 2px #b6b6b6;
						color: #b6b6b6;
						position: relative;
						text-align: left;
						width: 137px;

						i {
							background-image: url("../../images/refreshing.png");
							background-size: cover;
							display: block;
							height: 20px;
							position: absolute;
							right: 10px;
							top: 9px;
							width: 20px;

							-webkit-animation: rotateSpinner 1.2s linear infinite;
							animation: 1.2s linear 0s normal none infinite running rotateSpinner;
						}
					}

					.checkboxWrapper {
						input[type="checkbox"]:checked+label {
							background-color: #296cdc;
							border: 2px solid #296cdc;
						}

						input[type="checkbox"] {
							visibility: visible;
							opacity: 0;
							cursor: pointer;
							top: 4px;
							z-index: 9;
						}

						label {
							top: 4px;
						}

						span {
							width: auto;
						}
					}

					.keyword-details-wrapper {
						background-color: #fafafa;
						border-radius: 5px;
						margin-top: 10px;
						padding: 15px;
						width: 100%;

						p {
							color: #808080;
						}

						.table-wrapper {
							margin-top: 14px;
							background-color: #fff;
							padding: 20px;
							width: -moz-fit-content;
							width: -webkit-fit-content;

							table {
								tbody {
									padding: 5px;

									tr {
										.keyword-description {
											color: #808080;
										}

										td {
											&:first-child {
												padding-right: 30px;
											}
										}
									}
								}
							}
						}
					}
				}
			}

			.onboard-footer.fade {
				opacity: 0.7;
				pointer-events: none;

				a {
					color: #b6b6b6;
				}
			}

			.onboard-footer {
				height: 50px;
				padding: 14px 35px 17px 20px;
				width: 100%;

				a {
					color: #32a1f7;
				}

				a.pull-right {
					font-weight: 500;
					margin-left: 40px;
				}

				a.disabled {
					color: #b6b6b6;
				}
			}

		}
	}
}

.update-logo {

	.modal-dialog {
		width: 430px;

		.modal-content {
			width: 100%;

			.modal-header {
				border-bottom: 1px solid rgba(#000, 0.1);
				margin: 0;
				padding:  15px 18px 13px 18px;
				width: 100%;

				.modal-title {
					font-size: 16px;
					font-weight: 500;
					margin: 0;
					float: left;
				}

				.close {
					color: #333;
					float: right;
					font-size: 15px;
					font-weight: 500;
					margin: 3px 5px 0 0;

					&:hover,
					&:focus {
						text-decoration: none;
					}
				}
			}

			.pop-up-content {
				padding: 14px 17px;
				border-bottom: 1px solid #f5f5f5;

				img {
					float: left;
					margin-right: 13px;
					margin-top: 5px;
				}

				.team-name {
					font-size: 15px;
					margin: 0 0 7px;
				}

				.logo-description {
					font-size: 13px;
					color: #919191;
					line-height: 1.2;
				}
			}

			.pop-up-footer {
				padding: 12px 15px;
				height: 60px;

				.custom-file {
					color: #5a5a5a;
					font-size: 11px;
					font-weight: 400;
					padding-left: 5px;
					float: left;
					margin-top: 5px;

					#upload-logo {
						max-width: 211px;
						height: 21px;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
					}
				}

				.btn.btn-upload {
					width: 120px;
					height: 37px;
					float: right;
					background-color: #2bce9a;
					color: #fff;
				}

				.btn.btn-upload.disabled {
					background-color: #e6e6e6;
				}
			}

		}
	}
}

.job-distribution {
	.modal-dialog {
		width: 600px;

		.modal-content {
			background-color: #ffffff;
			.modal-body {
				padding: 20px;
			}
		}
	}
}

// 2/13/25/VR -> referencing to css for file upload modal component
.file-upload{
	.modal-dialog{
		margin-top: 160px;
		width: 850px;		
	}

	.modal-body{
		// padding-bottom:10px;
		
		.modal-header {
			background: #296cdc;
			position: sticky;
			top: 0;
			color: white;
		}

		.file-upload-list {
			overflow-y: scroll;
			max-height: 55vh;
		}
	}

}