@import './ui-elements.scss';
@import './modals.scss';

html {
	position: relative;
	min-height: 100%;
}

textarea {
	resize: none;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	-moz-appearance: none;
	margin: 0;
}

input[type="number"] {
	-moz-appearance: textfield;
}

body {
	font-family: "Roboto", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-size: 13px;
	background-color: #f8f8f8;
}

app-root {
	// Line 1
	display: flex;
	// Line 2
	flex-direction: column;
	// Line 3
	min-height: 100vh;
}

app-add-members,
app-dashboard,
app-findTalent,
app-interviews,
app-jobboard,
app-messages,
app-onboard,
app-profile,
app-settings,
add-members,
app-subscription,
app-verify-business,
app-reset-password,
app-change-password,
app-app-signup {
	flex-grow: 1;
}


body .breadcrumb>li+li::before {
	content: ">";
}

.word-wrap {
	white-space: pre-wrap;
}

//Limiting container minimum width in fluid layout
.container {
	//min-width:1140px;
	min-width: 990px;
	max-width: 1030px;
}

a {
	cursor: pointer;
	text-decoration: none;
}

.form-control{
	&.error {
		border-color: #f00 !important;
	}
}

.cursor-pointer {
	cursor: pointer;
}

//Custom fly from left to right animation
@keyframes ngdialog-flylrin {
	0% {
		opacity: 0;
		-webkit-transform: translateX(40px);
		transform: translateX(40px);
	}

	100% {
		opacity: 1;
		-webkit-transform: translateX(0);
		transform: translateX(0);
	}
}

@keyframes ngdialog-flylrout {
	0% {
		opacity: 1;
		-webkit-transform: translateX(0);
		transform: translateX(0);
	}

	100% {
		opacity: 0;
		-webkit-transform: translateX(40px);
		transform: translateX(40px);
	}
}

@keyframes ngdialog-flylrout {
	0% {
		opacity: 1;
		-webkit-transform: translateX(0);
		transform: translateX(0);
	}

	100% {
		opacity: 0;
		-webkit-transform: translateX(40px);
		transform: translateX(40px);
	}
}

/*preloader animation*/

@keyframes placeHolderAnimation {
	0% {
		background-position: -468px 0;
	}

	100% {
		background-position: 468px 0;
	}
}

@keyframes rotateSpinner {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

@keyframes popIn {
	0% {
		opacity: 0;
		transform: scale(0.7);
	}
}

.animated-background {
	animation-duration: 1s;
	animation-fill-mode: forwards;
	animation-iteration-count: infinite;
	animation-name: placeHolderAnimation;
	animation-timing-function: linear;
	background: #f6f7f8;
	background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
	background-size: 800px 104px;
	height: 17px;
	position: relative;
	border-radius: 10px;
}

.preloader-container {
	display: none;
	width: 100%;
	margin-top: 170px;

	.pre-loader-wrapper {
		margin: 0 auto;
		display: block;
		height: 24px;
		width: 24px;

		.preloader-icon {
			-webkit-animation: rotateSpinner 1.2s linear infinite;
			animation: 1.2s linear 0s normal none infinite running rotateSpinner;
			background-color: rgba(0, 0, 0, 0);
			background-image: url("../../images/icon-preload-gray.png");
			background-repeat: repeat;
			background-position: 0 0;
			background-size: cover;
			display: block;
			height: 24px;
			width: 24px;
		}
	}
}

.ngdialog-full-size-preloader-wrapper {
	position: absolute;
	width: 100%;
	height: calc(100% - 47px);
}

.dashboard-header {

	margin-bottom: 20px;
	float: left;
	width: 100%;

	h1 {
		margin: 0;
		font-size: 27px;
		font-weight: 300;
		color: #303030;
	}

	h5 {
		font-size: 15px;
		font-weight: 400;
		color: #919191;
	}

	.add-templates {
		background-color: #32a1f7;
		border: medium none;
		color: #fff;
		float: right;
		letter-spacing: -0.17px;
		margin: -3px 6px 0 0;
		padding: 8px 16px 8px 21px;
		box-shadow: 0px 3px 3px 0 rgba(0, 0, 0, 0.1);
	}

	.send-offer-dropdown-ui {
		position: absolute;
		z-index: 10;
		background-color: #ffffff;
		border-radius: 4px;
		border: 1px solid #eeeeee;
		top: 172px !important;
		right: 345px !important;
		left: auto !important;
		margin: 0;
		font-size: inherit;
		text-align: inherit;
		background-clip: inherit;
		box-shadow: inherit;
		float: inherit;
		list-style: inherit;

		&:before {
			content: "";
			display: block;
			width: 0;
			height: 0;
			border-left: 10px solid transparent;
			border-right: 10px solid transparent;
			border-bottom: 10px solid #fff;
			position: absolute;
			top: -9px;
			right: 10px;
			z-index: 1;
		}

		&:after {
			content: "";
			display: block;
			width: 0;
			height: 0;
			border-left: 11px solid transparent;
			border-right: 11px solid transparent;
			border-bottom: 12px solid #eeeeee;
			position: absolute;
			top: -11px;
			right: 9px;
		}

		.dropdown-ui-lists {
			list-style: none;
			padding: 0px 15px 0px 0px;
			margin: 0;
			overflow-y: scroll;

			li {
				cursor: pointer;
				padding: 3px 20px;
			}
		}
	}

}

.dashboard-body {
	margin-top: 71px;
	padding-bottom: 15px;
}

.interviewer-select-container {
	margin-top: -6px;
	float: right;
	display: flex;

	label {
		margin: 8px 5px 0 0;
		color: #838383;
		font-weight: 400;
	}

	.select-wrapper {
		.form-control {
			-moz-appearance: none;
			-webkit-appearance: none;
			background-image: url("../../images/image-sprite-1.png");
			background-position: 127px -97px;
			background-repeat: no-repeat;
			background-size: 500px;
			border-color: #adadad;
			box-shadow: none;
			box-sizing: border-box;
			padding: 0 30px 0 10px;
			width: 150px;
		}

		button {
			font-weight: 400;
			text-align: left;
		}

		.dropdown-menu li a {
			max-width: 160px;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
	}
}

//Dashboard widgets
.widget {
	background-color: #ffffff;
	border-radius: 5px;

	.widget-header {
		/* Madhu added line below border-bottom and padding from 5 to 14*/
		border-bottom: 0.1px solid #d1e8fb;
		padding: 18px 22px 14px;

		h1 {
			color: #5c5c5c;
			margin: 0;
			/* Madhu reduced from 16 to 14*/
			font-size: 14px;
			font-weight: 500;
		}
	}

	.widget-body {
		/* Madhu reduced from 12 to 6 */
		padding: 6px 24px 22px;

		.widget-highlight-text {
			font-weight: 500;
			color: #588ff4;
		}
	}

	.widget-body.scrollable {
		max-height: 390px;
		overflow: auto;
	}
}

.widget-header {
	.animated-background {
		width: 200px;
	}
}

.widget-body {
	li {
		height: 42px;

		.animated-background {
			width: 150px;
		}
	}

	li:first-child {
		.animated-background {
			width: 150px;
		}
	}

	li:nth-child(2n) {
		.animated-background {
			width: 200px;
		}
	}

	li:nth-child(3n) {
		.animated-background {
			width: 170px;
		}
	}

	li:nth-child(4n) {
		.animated-background {
			width: 300px;
		}
	}

	li:nth-child(5n) {
		.animated-background {
			width: 160px;
		}
	}
}

//-- Add to list dropdown
.add-to-list-dropdown-ui {
	position: absolute;
	z-index: 10;
	width: 250px;
	background-color: #ffffff;
	border-radius: 4px;
	border: 1px solid #eeeeee;
	margin: 0;
	padding: 0;
	font-size: inherit;
	text-align: inherit;
	background-clip: inherit;
	box-shadow: inherit;
	float: inherit;
	list-style: inherit;
	margin-top: 11px;

	&:before {
		content: "";
		display: block;
		width: 0;
		height: 0;
		border-left: 10px solid transparent;
		border-right: 10px solid transparent;
		border-bottom: 10px solid #fff;
		position: absolute;
		top: -9px;
		right: 10px;
		z-index: 1;
		margin-top: 11px;
	}

	&:after {
		content: "";
		display: block;
		width: 0;
		height: 0;
		border-left: 11px solid transparent;
		border-right: 11px solid transparent;
		border-bottom: 12px solid #eeeeee;
		position: absolute;
		top: 0px;
		right: 9px;
	}

	.dropdown-ui-search-list {
		padding: 8px 16px 0;
	}

	.available-lists-empty-state {
		color: #bdbdbd;
		text-align: center;
		font-size: 15px;
		padding: 38px 0;
	}

	.dropdown-ui-lists {
		list-style: none;
		padding: 3px 16px 0;

		height: 150px;
		overflow: auto;

		.checkboxWrapper {
			.animated-background {
				display: none;
			}
		}

		.preloader-container {
			display: block;
			margin-top: 20px;
		}
	}

	.dropdown-create-a-new-list {
		padding: 6px 0px;
		border-top: 1px solid #f5f5f5;

		.dropdown-create-new-list-button {
			width: 100%;
			text-align: left;
			text-indent: 3px;
		}

		.dropdown-create-new-list-form {
			padding: 6px 12px;

			.input-name-wrapper {
				margin-bottom: 10px;

				input.form-control {
					border: 1px solid #c7c7c7;
					box-shadow: none;
					border-radius: 0;
				}
			}

			.create-button-wrapper {
				margin-bottom: 1px;
			}
		}
	}
}

.add-to-list-dropdown-ui.on-pop-up {
	z-index: 10001;
}


.search-lists {

	.search-lists-container {
		position: relative;

		.form-control {
			border: 1px solid #c4c4c4;
			font-size: 13px;
			height: 40px;
			margin-bottom: 24px;
			box-shadow: none;
		}

		.icon-search-magnfying {
			position: absolute;
			left: 12px;
			top: 12px;
		}

		input {
			padding-left: 42px;
		}
	}
}

//-- Search lists form small
.search-lists-form-small {
	.form-control {
		border: 1px solid #c7c7c7;
		font-size: 14px;
		height: 34px;
		margin-bottom: 0;
		box-shadow: none;
	}

	.search-keyword,
	.search-lists {

		.search-keyword-container,
		.search-lists-container {
			position: relative;

			.icon-search-magnfying {
				position: absolute;
				left: 9px;
				top: 9px;
			}

			input {
				padding-left: 31px;
			}
		}
	}
}

.dropdown-ui-lists.loading {
	.checkboxWrapper {

		input,
		label,
		span {
			display: none !important;
		}

		.animated-background {
			display: block !important;
			margin-bottom: 10px;
		}
	}

	li {
		&:nth-child(2n) {
			.checkboxWrapper {
				.animated-background {
					width: 85%;
				}
			}
		}

		&:nth-child(3n) {
			.checkboxWrapper {
				.animated-background {
					width: 75%;
				}
			}
		}

		&:nth-child(4n) {
			.checkboxWrapper {
				.animated-background {
					width: 90%;
				}
			}
		}
	}
}

.list-widget {
	.widget-body {
		ul {
			list-style: none;
			padding: 0;

			li {
				a {
					position: relative;
					display: inline-block;
					color: #5c5c5c;
					font-size: 13px;
					text-decoration: none;
					border-bottom: 1px solid #f3f3f3;
					height: 42px;
					line-height: 42px;
					width: 100%;

					span.item-date {
						display: inline-block;
						float: left;
						min-width: 79px;
						margin-right: 18px;
					}

					span.item-title {
						display: inline-block;
						float: left;
						//width: 300px;
						height: 41px;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
					}

					span.item-candidates {
						/* Madhu changed 3 lines below */
						display: inline-block;
						color: #bdbdbd;
						float: right;
						width: 500px;

						.italic-text {
							font-style: italic;
						}
					}
				}

				a:hover {
					//background-color:#f6f3f3;
				}

				a:after {
					content: "";
					display: block;
					width: 8px;
					height: 17px;
					position: absolute;
					right: 10px;
					margin-top: -6px;
					top: 44%;
					background-image: url("../../images/image-sprite.png");
					background-size: 500px 250px;
					background-position: 0 -70px;
				}
			}

			li:last-child {
				a {
					border-bottom: 0;
				}
			}
		}
	}
}


.list-widget {
	.widget-body {
		ul {
			li {
				a {
					&:after {
						display: none;
					}
				}
			}
		}
	}
}

//SUB NAVIGATION
.sub-navigation-bar {
	background-color: #ffffff;
	margin-top: 63px;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 4;
	border-bottom: 1px solid #e5e5e5;
	box-shadow: 1px 1px 9px rgba(0, 0, 0, 0.1);

	ul {
		list-style: none;
		padding: 0;
		margin: 0;
		height: 44px;

		&.sub-navigation-list {
			height: auto;
			list-style: none;
			padding: 0;
			margin: 0;

			li {
				display: inline-block;
				float: left;

				a {
					color: #b6b6b6;
					display: inline-block;
					font-size: 13.9px;
					font-weight: 700;
					height: 37px;
					line-height: 24px;
					margin-left: 8px;
					margin-right: 34px;
					padding: 6px 0;
					position: relative;
					text-decoration: none;
					text-transform: uppercase;
				}

				a:hover {
					color: #a1a0a0;
				}

				&.active {
					a {
						color: #696969;
					}

					a:after {
						content: "";
						display: block;
						background-color: $primary_color_blue;
						width: 100%;
						height: 2px;
						position: absolute;
						bottom: 0;
						left: 0;
					}
				}
			}
		}
	}
}

//Breadcrumb
.unnanu-breadcrumb {
	background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
	margin: 0;
	padding-left: 0;
}

.sync-calendar-list-dropdown-ui {
	position: absolute !important;
	z-index: 10;
	background-color: #ffffff;
	border-radius: 4px;
	border: 1px solid #eeeeee;
	top: 12px !important;
	right: 0px !important;
	left: auto !important;
	margin: 0;
	padding: 17px;

	&:before {
		content: "";
		display: block;
		width: 0;
		height: 0;
		border-left: 10px solid transparent;
		border-right: 10px solid transparent;
		border-bottom: 10px solid #fff;
		position: absolute;
		top: -9px;
		right: 10px;
		z-index: 1;
	}

	&:after {
		content: "";
		display: block;
		width: 0;
		height: 0;
		border-left: 11px solid transparent;
		border-right: 11px solid transparent;
		border-bottom: 12px solid #eeeeee;
		position: absolute;
		top: -11px;
		right: 9px;
	}

	.dropdown-ui-lists {
		list-style: none;
		padding: 0px 15px 0px 0px;
		margin: 0;
		overflow-y: scroll;
		height: 200px;

		li {
			padding: 0px;

			.checkboxWrapper {
				input[type="checkbox"] {
					visibility: visible;
					opacity: 0;
					cursor: pointer;
					z-index: 9;

					&:focus+label {
						border-radius: 2px;
					}
				}

				input[type="checkbox"]:checked+label {
					background-color: #296cdc;
					border: 2px solid #296cdc;
				}

				label {
					color: #808080;
					margin-top: 3px;
				}

				span {
					width: auto;
				}
			}
		}
	}
}

.checkbox-wrapper {
	width: 25px;
	float: left;
	margin-top: 10px;
	position: relative;

	input[type="checkbox"] {
		height: 22px;
		left: 0;
		opacity: 0;
		position: absolute;
		top: 0;
		width: 22px;
		z-index: 2;
	}

	input[type="checkbox"]+label {
		background-image: url("../../images/image-sprite-1.png");
		background-position: -42px -135px;
		height: 22px;
		width: 22px;
		display: inline-block;
		padding: 0 0 0 0px;
	}

	input[type="checkbox"]:checked+label {
		background-image: url("../../images/image-sprite-1.png");
		background-position: -42px -166px;
		height: 22px;
		width: 22px;
		display: inline-block;
		padding: 0 0 0 0px;
	}
}

//Process Blocks
.process-container {
	padding-top: 10px;
	padding-bottom: 10px;
	border-bottom: solid 0.6px rgba(0, 0, 0, 0.12);

	.ui-process-wrapper {
		display: flex;
		border: 1px solid #e5e5e5;
		border-radius: 5px;
		background-color: #fafafa;
		width: 100%;

		.step-wrapper {
			text-align: center;
			color: #e1e1e1;
			font-size: 16px;
			font-weight: 500;
			padding: 15px 30px;
			position: relative;
			margin-right: 26px;

			span {
				background-color: #e1e1e1;
				color: #fff;
				display: inline-block;
				width: 25px;
				height: 25px;
				border-radius: 50%;
				text-align: center;
				font-size: 17px;
				line-height: 1.5;
			}

			&:first-child {
				border-radius: 5px 0 0 5px;
			}

			&:last-child {
				margin: 0;
				border-radius: 0 5px 5px 0;
				width: 195px;
			}
		}

		.step-wrapper.active {
			background-color: #32a1f7;
			color: #fff;

			span {
				background-color: #fff;
				color: #32a1f7;
			}
		}

		.step-wrapper.continue {
			&:after {
				content: "";
				display: block;
				height: 0;
				position: absolute;
				right: -28px;
				top: 0;
				width: 10px;
				background-image: url("../../images/image-sprite-1.png");
				background-position: -3px -136px;
				width: 30px;
				height: 55px;
			}
		}

		.step-wrapper.continue.active {
			&:after {
				border-bottom: 27px solid transparent;
				border-left: 28px solid #32a1f7;
				border-top: 28px solid transparent;
				width: 0;
				height: 0;
				background: none;
			}
		}

		.step-wrapper.active.prev {
			span {
				background-color: rgba(0, 0, 0, 0);
				background-image: url("../../images/image-sprite-1.png");
				background-repeat: repeat;
				background-position: -115px -134px;
				text-indent: -99999px;
			}

			&:after {
				border: none;
				background-image: url("../../images/image-sprite-1.png");
				background-position: -75px -136px;
				width: 30px;
				height: 55px;
			}
		}
	}

	.ui-process-edit-wrapper {
		display: flex;
		border: 1px solid #e5e5e5;
		border-radius: 55px;
		background-color: #fafafa;
		width: 100%;

		.step-wrapper {
			cursor: pointer;
			text-align: center;
			color: #e1e1e1;
			font-size: 16px;
			font-weight: 500;
			padding: 15px 30px;
			position: relative;

			span {
				background-color: #e1e1e1;
				color: #fff;
				display: inline-block;
				width: 25px;
				height: 25px;
				border-radius: 50%;
				text-align: center;
				font-size: 17px;
				line-height: 1.5;
			}

			&:first-child {
				border-radius: 26px 0 0 26px;
			}

			&:last-child {
				margin: 0;
				border-radius: 0 26px 26px 0;
				width: 195px;
			}
		}

		.step-wrapper.active {
			background-color: #32a1f7;
			color: #fff;

			span {
				background-color: #fff;
				color: #32a1f7;
			}

			&:first-child {
				border-radius: 26px 0 0 26px;
			}
		}
	}
}



tag-input {
	margin: 0;

	.ng2-tag-input {
		border: 1px solid #ccc !important;
		border-radius: 4px !important;
		box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075) !important;
		padding: 5px !important;

		tag-input-form {
			display: flex;
			flex-grow: 1;
			form {
				width: 100%;
				input {
					width: 100%;
				}
			}
		}
	}

	tag {
		height: 35px !important;
		line-height: 32px !important;
		border-radius: 2px !important;
		border: solid 1px #aecbff !important;
		background-color: #e6efff !important;
		background: #e6efff !important;
		font-size: 12px !important;
		font-weight: 500 !important;
		letter-spacing: 0.4px !important;
		padding: 0 12px !important;
		color: #3063bd !important;
		overflow-x: overlay !important;

		&:hover {
			color: #3063bd !important;
			background-color: #e6efff !important;
			background: #e6efff !important;
		}

		&:focus {
			box-shadow: none !important;
		}

		delete-icon {
			span {
				svg {
					path {
						fill: #3063bd !important;
					}
				}
			}
		}
	}
}

.icon-search-types-job-titles {
	width: 18px;
	height: 18px;
	display: inline-block;
	background-position: -63px -164px;
}

.icon-search-types-saved-lists {
	width: 18px;
	height: 18px;
	display: inline-block;
	background-position: -63px -183px;
}

//Shared images sprites
.img-sprite-1 {
	background-image: url("../../images/image-sprite.png");
	background-size: 460px 230px;
}

.img-sprite-2 {
	background-image: url("../../images/image-sprite-1.png");
}

.unnanu-select-dropdown-wrapper {
	position: relative;
	z-index: 2;

	button {
		/* Madhu change center align */
		text-align: center;
		line-height: 30px;
		font-size: 13px;
		font-weight: 500;
		color: #757575;
		margin-top: 1px;
		margin-left: 5px;
		width: 176px;
		height: 30px;
		border-radius: 15px;
		border: solid 1px #dcdcdc;
		box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
		background-image: linear-gradient(to bottom, #ffffff, #e2e0e0);
		padding: 0 15px;

		svg {
			float: right;
			margin-top: 12px;
		}

		&:hover {
			color: #757575;
		}
	}
}


//Custom Checkbox
.checkboxWrapper {
	margin-top: 8px;
	margin-bottom: 9px;
	margin-right: 15px;
	position: relative;
	display: block;

	label {
		width: 14px;
		height: 14px;
		margin: 0;
		cursor: pointer;
		position: absolute;
		top: 1px;
		left: 0;
		border-radius: 2px;
		box-shadow: none;
		border: 2px solid #bfbfbf;
	}

	label:after {
		content: "";
		width: 9px;
		height: 5px;
		position: absolute;
		top: 1px;
		left: 1px;
		border: 2px solid #fcfff4;
		border-top: none;
		border-right: none;
		background: transparent;
		opacity: 0;
		-webkit-transform: rotate(-45deg);
		transform: rotate(-45deg);
	}

	label:hover::after {
		opacity: 0.3;
	}

	input[type="checkbox"] {
		opacity: 0;
		cursor: pointer;
		position: absolute;
		left: 0;
		top: 2px;
		margin: 0;
		width: 16px;
		height: 16px;
		z-index: 10009;
	}

	input[type="checkbox"]:checked+label {
		background-color: $primary_color_blue;
		border: 2px solid $primary_color_blue;
	}

	input[type="checkbox"]:checked+label:after {
		opacity: 1;
	}

	>span {
		display: block;
		margin-left: 21px;
		width: 167px;
		word-wrap: break-word;
	}
}

// General icons

.icon-scheduled-interviews {
	width: 23px;
	height: 19px;
	display: inline-block;
	background-position: -22px -50px;
}

.icon-new-referral-activities {
	width: 23px;
	height: 21px;
	display: inline-block;
	background-position: -22px -87px;
}

.member-manage-caret {
	display: block;
}

.icon-caret-right {
	width: 8px;
	height: 13px;
	margin-bottom: -2px;
	margin-left: 5px;
	background-position: 0 -66px;
}

.icon-caret-down {
	width: 13px;
	height: 8px;
	margin-top: 3px;
	margin-bottom: 2px;
	background-position: 0 -82px;
}

.icon-send-mail-to-user {
	display: block;
	width: 17px;
	height: 13px;
	background-position: -22px -109px;
}

.icon-send-mail-to-user-large {
	display: inline-block;
	width: 23px;
	height: 17px;
	background-position: -20px -164px;
}

.icon-resume-to-user-large {
	display: inline-block;
	background-image: url("../../images/view-resume.png");
	background-size: cover;
	background-position: center;
	height: 31px;
	width: 37px;
}

.icon-search-magnfying {
	display: block;
	width: 16px;
	height: 16px;
	background-position: 0px -101px;
}

.icon-location-compass {
	display: block;
	width: 18px;
	height: 18px;
	background-position: 0px -118px;
}

.icon-delete-user-from-list {
	display: block;
	width: 9px;
	height: 9px;
	background-position: -22px -123px;
}

.icon-save-search-button {
	display: inline-block;
	width: 15px;
	height: 15px;
	background-position: -50px -70px;
}

.icon-add-to-list-button {
	width: 18px;
	height: 18px;
	display: inline-block;
	background-position: 0 -164px;
}

.icon-share-button {
	width: 18px;
	height: 18px;
	display: inline-block;
	background-position: -60px -196px;
}

.icon-dropdown-create-icon-blue {
	width: 16px;
	height: 16px;
	display: inline-block;
	background-position: -44px -164px;
}

.icon-new-offer-activities {
	width: 23px;
	height: 17px;
	display: inline-block;
	background-position: -22px -70px;
}

.img-account-dropdown {
	width: 7px;
	height: 4px;
	display: inline-block;
	background-position: -13px -52px;
}

.icon-unread-messages {
	width: 23px;
	height: 17px;
	display: inline-block;
	background-position: -22px -32px;
}

//Social icons
[class*="icon-social"] {
	display: inline-block;
	height: 15px;
	opacity: 0.8;
	-webkit-transition: opacity 0.2s ease-in-out;
	transition: opacity 0.2s ease-in-out;
	vertical-align: middle;
}

.icon-social-facebook {
	width: 15px;
	background-position: 0px 0px;
}

.icon-social-twitter {
	width: 17px;
	background-position: -16px 0px;
}

.icon-social-instagram {
	width: 15px;
	background-position: -34px 0px;
}

.icon-social-linkedin {
	background-image: url("../../images/linkedin-3-16.png");
	background-size: 16px 16px;
	width: 16px;
	opacity: 0.42;
}

//Error Messages
.errorMsg {
	width: 100%;
	color: #ff5f5f;
}

.errorAlert {
	font-size: 12px;
	border: 1px solid #ff5f5f;
	border-radius: 5px;
	padding: 10px 40px;
	line-height: 18px;
	margin-bottom: 8px;
	background-color: #ffebeb;
	position: relative;

	.cancel-icon {
		width: 22px;
		height: 22px;
		fill: #ff5f5f;
		float: left;
		margin-top: -12px;
		position: absolute;
		left: 7px;
		top: 50%;
		//margin:7px;
		//@media (min-width: 992px) {
		//margin:0px -15px 7px -32px;
		//}
	}
}


// ngb datepicker overwrite
ngb-datepicker {
	margin: 10px auto 0;
	width: 260px;
	height: 256px;

	.ngb-dp-header {
		ngb-datepicker-navigation {
			.ngb-dp-arrow {
				height: 4rem;

				.ngb-dp-navigation-chevron {
					height: 1em;
					width: 1em;
				}
			}

			.ngb-dp-navigation-select {
				flex: 3 1 9rem;

				>.custom-select {
					font-size: 1.3rem;
					height: 2.5rem;
				}
			}
		}
	}

	.ngb-dp-months {
		height: 210px;

		.ngb-dp-month {
			width: 258px;

			ngb-datepicker-month {
				height: 210px;

				.ngb-dp-day,
				.ngb-dp-week-number,
				.ngb-dp-weekday {
					height: 3rem;
					width: 4rem;
				}

				.ngb-dp-day {
					[ngbDatepickerDayView] {
						width: auto !important;

						&.bg-primary {
							background-color: #32a1f7 !important;
						}
					}
				}
			}
		}
	}
}

// Accordion list item

.list-item {
	&.card {
		border: none !important;

		.card-header,
		.card-header:first-child {
			border: none !important;
			padding: 0px !important;
			background-color: #fff !important;
		}
	}

	.list-item-header {
		border-bottom: solid 0.6px rgba(0, 0, 0, 0.1);
		color: #4a4a4a;
		cursor: pointer;
		font-size: 15px;
		font-weight: 500;
		height: 59px;
		line-height: 32px;
		padding: 11px 35px 16px 15px;
		position: relative;
		word-wrap: break-word;

		i {
			background-image: url("../../images/arrow.png");
			background-size: cover;
			background-position: center;
			background-repeat: no-repeat;
			display: inline-block;
			height: 7.8px;
			width: 14.4px;
			position: absolute;
			top: 25px;
			right: 18px;
		}
	}

	.list-item-header.open {
		i {
			transform: rotate(180deg);
		}
	}

	.list-item-content-empty {
		background-color: #f4f4f4;
		border-bottom: solid 0.6px rgba(0, 0, 0, 0.1);
		color: #9b9b9b;
		font-size: 13px;
		height: 89px;
		line-height: 89px;
	}
}


.user-send-message-button-large {
	border: 1px solid $primary_color_blue;
	color: $primary_color_blue;
	background: #ffffff;
	box-shadow: none;
	width: 148px;
	height: 38px;
	box-shadow: none;
	border-radius: 4px;
	text-align: center;
	font-size: 13px;

	i {
		margin: -3px 2px 0 0;
		vertical-align: middle;
	}

	&:hover {
		color: $primary_color_blue;
	}

	&:disabled {
		opacity: 0.2;
	}
}


// Custom toast style
.toast-container {
	&.toast-top-center {
		top: 71px;
	}
	.custom-toastr {
		width: fit-content !important;
		text-align: center;
		z-index: 10002;
		&.ngx-toastr {
			padding: 8px 18px;
			border-radius: 6px;
			border-width: 1px;
			border-style: solid;
			font-size: 12px;
		}

		&.toast-success {
			color: #48ac68;
			background-color: #edfaf1;
			border-color: #48ac68;
			background-image: none;
		}

		&.toast-error {
			color: #e82110;
			background-color: #ffe3e3;
			border-color: #e82110;
			background-image: none;
		}

		&.toast-info {
			color: #2F96B4;
			background-color: #e3f4f9;
			border-color: #2F96B4;
			background-image: none;
		}

		&.toast-warning {
			color: #F89406;
			background-color: #f7e6cf;
			border-color: #F89406;
			background-image: none;
		}
	}
}

// Trix editor styles override
trix-toolbar {
	.trix-button-row {
		justify-content: flex-start;;
		.trix-button-group--file-tools,
		.trix-button-group-spacer {
			display: none !important;
		}
	}
}

.trix-content {
	height: 338px;
	overflow: auto;
}

.ng-select {
	height: 30px;
	&.ng-select-single {
		.ng-select-container {
			height: 30px;
		}
	}
	.ng-select-container {
		border-radius: 20px;
		min-height: 30px;
		.ng-value-container {
			padding-left: 19px;
			.ng-input {
				padding-left: 19px !important;
			}
		}
	}
	.ng-arrow-wrapper {
		padding-right: 14px;
	}
}
