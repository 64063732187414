@import './colors.scss';

// .icon-delete-account {
//     width: 9px;
//     height: 9px;
//     display: inline-block;
//     background-position:0 -91px;
// }
//Buttons
button.btn {
  position: relative;
  font-weight: 500;
}
button.btn:disabled {
  background-color: #dddde0 !important;
  color: #ffffff;
  opacity: 1;
  -moz-opacity: 1;
  -khtml-opacity: 1;
  border: none !important;
  box-shadow: none !important;
  pointer-events: none;
}
button.active-button-small-green {
  background-color: $button_color_green;
  color: #ffffff;
  font-size: 15px;
  width: 90px;
  height: 44px;
  text-align: center;
}
button.active-button-small-green:hover {
  color: #ffffff;
}
button.btn:disabled .smallButtonPreloader {
  display: block;
}
button.upload-w9-button {
  background-color: #ffffff;
  border: 2px solid $primary_color_blue;
  color: $primary_color_blue;
  font-size: 18px;
  font-weight: 500;
  vertical-align: middle;
  width: 184px;
  height: 48px;
  i.img-sprite-1 {
    vertical-align: middle;
    display: inline-block;
    width: 22px;
    height: 20px;
    background-position: 0px -31px;
    margin-right: 20px;
    margin-top: -4px;
  }
}
button.upload-w9-button:hover {
  background-color: #ffffff;
  border: 2px solid $primary_color_blue;
  color: $primary_color_blue;
}
button.make-admin-button {
  width: 134px;
  background: none;
  color: #32a1f7;
  border-radius: 3px;
  border: 1px solid #32a1f7;
  background-color: #ffffff;

  &:focus {
    color: #32a1f7;
  }
}
button.make-admin-button:hover {
  color: #188de8;
  background-color: #ffffff;
}
button.remove-admin-button {
  width: 134px;
  background: none;
  color: #ff3455;
  border-radius: 3px;
  border: 1px solid #ff3455;
  background-color: #ffffff;

  &:focus {
    color: #ff3455;
  }
}
button.remove-admin-button:hover {
  color: #ff3455;
  background-color: #ffffff;
}
button.delete-account-button {
  background: none;
  color: #919191;
}
button.delete-account-button:hover {
  color: #ff3455;
}
button.search-talent-button {
  @media (min-width: 992px) {
    width: 133px;
  }
  @media (min-width: 1200px) {
    width: 146px;
  }
  height: 40px;
  color: #ffffff;
  background-color: #32a1f7;
  box-shadow: 0px 3px 3px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px #c5d9e8;
}
button.search-talent-button:hover {
  color: #ffffff;
  background-color: #2680dc;
}
button.create-a-list-button {
  background-color: #32a1f7;
  color: #ffffff;
}
button.create-a-list-button:hover {
  color: #ffffff;
}
button.dropdown-create-new-list-button {
  background: none;
  color: #707070;
  font-weight: 400;
  font-size: 15px;
  i {
    margin: -3px 4px 0 0;
    vertical-align: middle;
  }
}
button.user-add-to-list-button-large {
  box-shadow: 0px 3px 3px 0 rgba(0, 0, 0, 0.1);
  width: 148px;
  height: 38px;
  background: $button_color_green;
  box-shadow: none;
  border-radius: 4px;
  text-align: center;
  color: #ffffff;
  font-size: 13px;
  i {
    margin: -2px 8px 0 0;
    vertical-align: middle;
  }
  &:hover {
    color: #ffffff;
    background: $button_color_green_hover;
  }
  &:focus {
    color: #ffffff;
    background: $button_color_green_hover;
  }
  &:disabled {
    opacity: 0.2;
    background: $button_color_green !important;
  }
}

button.share-button-large {
  box-shadow: none;
  width: 140px;
  height: 38px;
  background: #32a1f7;
  box-shadow: none;
  border-radius: 4px;
  text-align: center;
  color: #ffffff;
  margin-left: 13px;
  font-size: 13px;
  i {
    margin: -2px 8px 0 -10px;
    vertical-align: middle;
  }
}
button.share-button-large:hover {
  color: #ffffff;
  background: #296cdc;
}
button.share-button-large:focus {
  color: #ffffff;
  background: #296cdc;
}
button.user-remove-from-list-button {
  border: 1px solid #ff6a6a;
  box-shadow: none;
  width: 34px;
  height: 30px;
  background: #ffffff;
  box-shadow: none;
  border-radius: 4px;
  text-align: center;
  i {
    margin: 0 5px;
  }
}
button.new-talent-search-button {
  background-color: #32a1f7;
  color: #ffffff;
  border: 0;
  border-radius: 4px;
  width: 182px;
  height: 44px;
  font-size: 15px;
  font-weight: 600;
}
button.save-search-button {
  background-color: #ffffff;
  border: 1px solid #9e9e9e;
  color: #989898;
  font-size: 14px;
  font-weight: 500;
  height: 32px;
  line-height: 18px;
  padding: 7px 8px;
  width: 115px;

  &:hover {
    background-color: #eee;
  }
  i {
    vertical-align: middle;
    margin-right: 4px;
    margin-top: -2px;
  }
}
.upgrade-to-pro-card {
  width: 148px;
  height: 38px;
  box-shadow: none;
  border-radius: 4px;
  text-align: center;
  color: #ffffff;
  font-size: 13px;
  box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.08);
  background-color: #548ef4;
  text-decoration: none !important;
  line-height: 24px;
  &:focus {
    color: #ffffff;
  }
  &:hover {
    color: #ffffff;
    background-color: #3b7aeb;
  }
}
// -- Signin button
.webappSigninButton {
  width: 100%;
  background-color: #3ae19c;
  color: #ffffff;
  font-size: 18px;
  height: 54px;
  border-radius: 6px;
  margin-bottom: 0;
  transition: 0.2s;

  &:hover,
  &:focus {
    color: #fff;
    background-color: #49c592;
  }
}
//Preloaders
.large-button-preloader,
.account-access-preloader {
  display: inline-block;
  width: 30px;
  height: 30px;
  position: absolute;
  top: 12px;
  right: 10px;
}
.onboard-footer {
  button {
    .large-button-preloader,
    .account-access-preloader {
      top: 6px;
      right: 7px;
    }
  }
}

.large-button-preloader,
.account-access-preloader {
	&::after {
		content: "";
		display: inline-block;
		width: 30px;
		height: 30px;
		background-image: url(../../images/icon-preload-white.png);
		-webkit-animation: rotateSpinner 1.2s linear infinite;
		background-repeat: no-repeat;
		background-position: center;
		z-index: 1;
	}
}

//Icons
.green-check-icon {
  display: inline-block;
  width: 27px;
  height: 27px;
  background-position: -72px 0;
}
.verification-success-icon {
  display: inline-block;
  width: 110px;
  height: 110px;
  background-position: -99px -4px;
}
.paper-airplane-icon {
  display: inline-block;
  width: 130px;
  height: 122px;
  background-position: -221px 0;
}

//Password strength meter
.password-strength-meter {
  padding: 5px 0 0 0;
  list-style: none;
  li {
    float: left;
    width: 24%;
    margin-right: 1%;
    height: 3px;
    background-color: #dedede;
    border-radius: 2px;
  }
}
// Password strength levels
.strength-level-1 {
  .strength1 {
    background-color: rgb(200, 24, 24);
  }
}
.strength-level-2 {
  .strength1,
  .strength2 {
    background-color: rgb(255, 172, 29);
  }
}
.strength-level-3 {
  .strength1,
  .strength2,
  .strength3 {
    background-color: rgb(166, 192, 96);
  }
}
.strength-level-4 {
  .strength1,
  .strength2,
  .strength3,
  .strength4 {
    background-color: rgb(39, 179, 15);
  }
}
//password hint text
.password-hint {
  color: #353232;
  margin-bottom: 26px;
  font-size: 13px;
}
//DASHBOARD UI ELEMENTS
.dashboard-content-panel {
  border: 0.6px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
  padding: 20px;
  border-radius: 4px;
  background-color: #ffffff;
  float: left;
  width: 100%;
}


.unnanu-select-wrapper {
  margin-top: 1px;
  margin-left: 5px;
  width: 96px;
  height: 30px;
  border-radius: 15px;
  border: solid 1px #dcdcdc;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  background-image: linear-gradient(to bottom, #ffffff, #e2e0e0);
  padding: 0 6px;
  select.form-control {
    border: none;
    box-shadow: none;
    background: none;
    height: 30px;
  }
}

textarea.auto-expand-input {
  min-height: 50px;
  max-height: 110px;
}

.rotate-preloader-fill {
  width: 100%;
  height: 100%;
  position: relative;
  .pre-loader-wrapper {
    position: absolute;
    left: 50%;
    top: 50%;
    height: 24px;
    width: 24px;
    margin-left: -12px;
    margin-top: -12px;
    .preloader-icon {
      -webkit-animation: rotateSpinner 1.2s linear infinite;
      animation: 1.2s linear 0s normal none infinite running rotateSpinner;
      background-color: rgba(0, 0, 0, 0);
      background-image: url("../../images/icon-preload-gray.png");
      background-repeat: repeat;
      background-position: 0 0;
      background-size: cover;
      display: block;
      height: 24px;
      width: 24px;
    }
  }
}


.icon-start-date {
  opacity: 0.7;
  display: inline-block;
  width: 17px;
  height: 17px;
  background-image: url("../../images/info-gray.png");
  background-size: cover;
}

.blur {
  filter: blur(0.2rem);
}
