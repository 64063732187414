$primary_color_blue: #296cdc;
$primary_color_gray: #989898;
$text_color_gray_1: #aeaeae;

$bodyColor_blue1: #061d4d;
$colorBlue_1_hover: #0e54e6;

$button_color_green: #29dca7;
$button_color_green_hover: #08be88;

$buttonColor_green1	:#2ddbb6;
$buttonColor_green1_hover	:#14b897;
